import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { getValidResourceUrl } from '../../../../../common/lib';

import { DescTextImg } from '../../../../../@type/webView/wiki';

import { palette } from '../../../../../lib/styles/palette';

import SubTitle from '../../../../common/SubTitle';

interface DescriptionTextImgProps {
  data: DescTextImg;
}

function DescriptionTextImg({
  data: { title, imageUrl, contents },
}: DescriptionTextImgProps): ReactElement {
  return (
    <div css={wrap}>
      {title && <SubTitle>{title}</SubTitle>}
      <div className="content">
        <img
          src={getValidResourceUrl(imageUrl, 'wikiImage')}
          alt={`${title}_이미지`}
        />
        <div>{contents}</div>
      </div>
    </div>
  );
}

export default DescriptionTextImg;

const wrap = css`
  padding: 0 20px;
  font-size: 15px;
  line-height: 23px;
  white-space: pre-wrap;
  margin-bottom: 32px;

  .content {
    color: ${palette.gray6};
    img {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 20px;
    }
  }
`;
