import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Table, Pagination, Switch, PageHeader, Input } from 'antd';
import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import { WIKI_ADMIN_URL } from '../common/const';

const { Search } = Input;

interface WikiType {
  key: string;
  name: string;
  documentSeq: number;
  categoryName: string;
  documentTitle: string;
  documentDesc: string;
  regName: string;
  regDate: string;
  exposeYn: number;
}

const WikiList = (): JSX.Element => {
  const [wikiList, setWikiList] = useState<WikiType[]>([]);
  const [wikiListCount, setWikiListCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentSize, setCurrentSize] = useState<number>(10);
  const [currentSearchKeyword, setCurrentSearchKeyword] = useState<string>();

  const paginationRef = useRef<any>();

  const columns: ColumnsType<WikiType> = [
    {
      title: '번호',
      dataIndex: 'documentSeq',
      key: 'documentSeq',
      width: 90,
    },
    {
      title: '카테고리',
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: 180,
    },
    {
      title: '제목',
      dataIndex: 'documentTitle',
      key: 'documentTitle',
      render: (text: string, record: any) => (
        <Link to={`/wiki/info/${record.documentSeq}`}>{text}</Link>
      ),
    },
    {
      title: '개요',
      dataIndex: 'documentDesc',
      key: 'documentDesc',
      render: (documentDesc: string) => {
        return (
          <div>
            {documentDesc.length < 50
              ? documentDesc
              : `${documentDesc.slice(0, 35)}...`}
          </div>
        );
      },
    },
    {
      title: '조회수',
      dataIndex: 'totalClick',
      key: 'totalClick',
      width: 75,
    },
    {
      title: '노출 여부',
      dataIndex: 'exposeYn',
      key: 'exposeYn',
      width: 120,
      render: (exposeYn: number, record: any, index: number) => {
        return (
          <Switch
            checked={exposeYn === 1}
            onChange={() => {
              changeExpose({
                documentSeq: record.documentSeq,
                exposeYn: exposeYn ? 0 : 1,
                index,
              });
            }}
          />
        );
      },
    },
    {
      title: '등록자',
      dataIndex: 'regName',
      key: 'regName',
      width: 180,
    },
    {
      title: '등록일',
      dataIndex: 'regDate',
      key: 'regDate',
      width: 180,
    },
  ];

  useEffect(() => {
    getWikiList({});
  }, []);

  const getWikiList = async ({
    page = 1,
    size = 10,
    searchKeyword,
  }: {
    page?: number;
    size?: number;
    searchKeyword?: string;
  }) => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/wiki?page=${page}&size=${size}${
        searchKeyword ? `&searchKeyword=${searchKeyword}` : ''
      }`,
    );
    const { rows, count } = res.data.data;
    paginationRef?.current?.chagePage(page);
    setWikiList(rows);
    setWikiListCount(count);
    setIsLoading(false);
    return rows;
  };

  const changeExpose = async ({ documentSeq, exposeYn, index }: any) => {
    const res = await axios.patch(`${WIKI_ADMIN_URL}/wiki`, {
      documentSeq,
      exposeYn,
    });
    const newWikiList = [...wikiList];
    newWikiList[index].exposeYn = exposeYn;
    setWikiList(newWikiList);
    return res;
  };

  const onPageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setCurrentSize(size);
    getWikiList({ page, size, searchKeyword: currentSearchKeyword });
  };

  const onSearch = (searchKeyword: string) => {
    setCurrentSearchKeyword(searchKeyword);
    getWikiList({ page: 1, size: currentSize, searchKeyword });
  };

  return (
    <>
      <PageHeader className="wiki-list-header" title="문서 목록" />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: '90%',
        }}
      >
        <div style={{ width: '100%', textAlign: 'end' }}>
          <Search
            onSearch={onSearch}
            placeholder="문서 번호 or 제목"
            style={{ width: 350, marginBottom: 20 }}
          />
        </div>
        <Table
          style={{ width: '100%' }}
          loading={isLoading}
          columns={columns}
          dataSource={wikiList}
          pagination={false}
          // scroll={{ x: 1750, y: 1000 }}
        />
        <WikiListPagination
          total={wikiListCount}
          onChange={onPageChange}
          ref={paginationRef}
        />
      </div>
    </>
  );
};
export default WikiList;

const WikiListPagination = forwardRef(
  (
    {
      total = 10,
      onChange = () => {},
    }: {
      total?: number;
      onChange?: (page: number, size: number) => void;
    },
    ref: any,
  ): JSX.Element => {
    const [curruntPage, setCurruntPage] = useState<number>(1);

    useImperativeHandle(ref, () => ({
      chagePage(page: number) {
        setCurruntPage(page);
      },
    }));

    const onClickPage = (page: number, size: number) => {
      setCurruntPage(page);
      onChange(page, size);
    };

    return (
      <>
        <Pagination
          style={{ marginTop: '20px', width: '100%', textAlign: 'end' }}
          current={curruntPage}
          total={total}
          onChange={onClickPage}
          pageSizeOptions={['10', '20', '50']}
        />
      </>
    );
  },
);
WikiListPagination.displayName = 'WikiListPagination';
