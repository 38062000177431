import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload } from 'antd';
import {
  ConsoleSqlOutlined,
  SettingFilled,
  UploadOutlined,
} from '@ant-design/icons';
import WikiUploadImg from './WikiUploadImg';

const imageDesc = [
  {
    type: 'description',
    data: [
      {
        type: 'image',
        data: {
          title: '참고 이미지',
          imageUrl:
            'http://img2.joongna.com/wiki/document/2019/09/09/20190909160905_1.jpg',
        },
      },
    ],
  },
];

interface WikiImageFormProps {
  formId: string;
  onChange: (id: any, data: any) => void;
}

const WikiImageForm: React.FC<WikiImageFormProps> = ({ formId, onChange }) => {
  const [img, setImg] = useState<any>();
  const onValuesChange = (changedValues: any, allValues: any) => {
    const data = {
      type: 'image',
      data: {
        title: allValues.title,
        imageUrl: allValues.descriptionImage[0],
      },
    };
    onChange(formId, data);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      setImg(e[0]);
      return e;
    }
    setImg(e?.fileList[0]);
    return e?.fileList;
  };

  return (
    <Form
      name="이미지"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onValuesChange={onValuesChange}
      autoComplete="off"
    >
      <Form.Item
        label="제목"
        name="title"
        rules={[{ required: true, message: '항목의 제목을 입력해주세요' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="descriptionImage"
        label="이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <WikiUploadImg maxCount={1} />
      </Form.Item>
    </Form>
  );
};

export default WikiImageForm;
