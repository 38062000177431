import React, { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { WIKI_ADMIN_URL } from '../common/const';
import WikiDebounceSelect from './WikiDebounceSelect';

// Usage of DebounceSelect
interface CategoryValue {
  label: string;
  value: string;
  data: any;
}

interface WikiCategorySelectProps {
  value?: any;
  onChange?: (value: any) => void;
  maxDepth?: number;
}

const WikiCategorySelect: React.FC<WikiCategorySelectProps> = ({
  value = {},
  onChange,
  maxDepth,
}) => {
  const [categoryValue, setCategoryValue] = useState<CategoryValue>();

  const getCategoryList = async (input: string | number): Promise<any> => {
    try {
      if (!input) {
        return [];
      }
      const res = await axios.get(
        `${WIKI_ADMIN_URL}/category?searchKeyword=${input}`,
      );
      const { data = [] } = res.data;
      if (maxDepth) {
        _.remove(data, (item: any) => item.categoryDepth > maxDepth);
      }
      const parsedData = data.map((item: any) => {
        return {
          label: `${
            item.parentsCategoryName ? `${item.parentsCategoryName} > ` : ''
          }${item.categoryName}`,
          value: JSON.stringify(item),
          data: item,
          key: item.categorySeq,
        };
      });

      return parsedData;
    } catch (error) {
      return error;
    }
  };

  const triggerChange = (newValue: any) => {
    const parsedValue = JSON.parse(newValue.value);
    const data = [];
    if (parsedValue.parentsCategorySeq) {
      data.push({
        categorySeq: parsedValue.parentsCategorySeq,
        categoryName: parsedValue.parentsCategoryName,
      });
    }
    data.push({
      parentsCategorySeq: parsedValue.parentsCategorySeq,
      categorySeq: parsedValue.categorySeq,
      categoryName: parsedValue.categoryName,
      categoryDepth: parsedValue.categoryDepth,
    });
    onChange?.(data);
  };

  return (
    <WikiDebounceSelect
      showSearch
      value={categoryValue}
      placeholder="카테고리 번호 or 카테고리명"
      fetchOptions={getCategoryList}
      onChange={(newValue) => {
        triggerChange(newValue);
        setCategoryValue(newValue as CategoryValue);
      }}
      style={{ width: '100%' }}
    />
  );
};

export default WikiCategorySelect;
