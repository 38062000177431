import axios from 'axios';
import clearWikiIdToken from './clearWikiIdToken';

const writeWikiIdToken = async (IdToken: string) => {
  sessionStorage.setItem('wikiIdToken', IdToken);
  axios.defaults.headers.common.Authorization = IdToken;
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        clearWikiIdToken();
      }
      return Promise.reject(error);
    },
  );
  return true;
};
export default writeWikiIdToken;
