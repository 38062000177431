import { ReactElement } from 'react';

import {
  WikiDocument,
  WikiCategory,
  WikiItems,
} from '../../../@type/webView/wiki';

import WikiItemDescription from './WikiItemDescription';
import WikiItemRelatedDocuments from './WikiItemRelatedDocuments';
import WikiItemRelatedProducts from './WikiItemRelatedProducts';

interface WikiItemPerTypeProps {
  document: WikiDocument;
  documentCategories: WikiCategory[];
  documentItem: WikiItems;
}

function WikiItemPerType({
  document,
  documentItem,
  documentCategories,
}: WikiItemPerTypeProps): ReactElement {
  switch (documentItem.type) {
    case 'description':
      return (
        <WikiItemDescription
          data={documentItem.data}
          document={document}
          documentCategories={documentCategories}
        />
      );
    case 'relatedProducts':
      return <WikiItemRelatedProducts data={documentItem.data} />;
    case 'relatedDocuments':
      return <WikiItemRelatedDocuments data={documentItem.data} />;
    default:
      return <></>;
  }
}

export default WikiItemPerType;
