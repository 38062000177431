import { Form, Input, DatePicker, Switch, Button, InputNumber } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import WikiUploadImg from './WikiUploadImg';
import WikiCategorySelect from './WikiCategorySelect';
import { WIKI_IMG_URL } from '../common/const';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface WikiDataFormProps {
  initialValues: any;
  visible: boolean;
  nextStep: (data: any) => void;
}

const WikiDataForm: React.FC<WikiDataFormProps> = ({
  initialValues,
  visible,
  nextStep,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    nextStep(values);
  };

  return (
    <Form
      style={{
        display: visible ? 'block' : 'none',
        width: '80%',
      }}
      {...formItemLayout}
      form={form}
      name="위키 문서 정보"
      onFinish={onFinish}
      onValuesChange={(changedValues, allValues) => {
        // console.log('onValuesChange: ', allValues);
      }}
      initialValues={{
        documentCategories: [
          {
            categoryName:
              initialValues.category[initialValues.category.length - 1]
                .categoryName,
            categorySeq:
              initialValues.category[initialValues.category.length - 1]
                .categorySeq,
          },
        ],
        documentTitle: initialValues.wiki.documentTitle,
        documentDesc: initialValues.wiki.documentDesc,
        commentYn: initialValues.wiki.commentYn,
        originalPrice: initialValues.wiki.originalPrice,
        unopenedPrice: initialValues.wiki.unopenedPrice,
        usedPrice: initialValues.wiki.usedPrice,
        standardPriceKeyword: initialValues.wiki.standardPriceKeyword,
        documentImgs: initialValues.image
          ? initialValues.image.map((item: any, index: number) => {
              return {
                uid: `${index}`,
                name: item.documentImgUrl.split('/')?.pop(),
                url: `${WIKI_IMG_URL}${item.documentImgUrl}`,
                status: 'done',
              };
            })
          : [],
      }}
      scrollToFirstError
    >
      <Form.Item
        name="documentCategories"
        label="카테고리"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <WikiCategorySelect />
      </Form.Item>
      <div
        style={{
          marginLeft: '33.333%',
          marginTop: '-22px',
          marginBottom: '5px',
        }}
      >
        이전 카테고리 :
        {initialValues.category
          .map((item: any) => {
            return `${item.categoryName}`;
          })
          .join(' > ')}
      </div>
      <Form.Item
        name="documentTitle"
        label="제목"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="documentDesc"
        label="개요"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      {/* 
      <Form.Item
        label="노출 기간 제한"
        name="unlimitedYn"
        valuePropName="checked"
      >
        <Switch onChange={setUseDate} />
      </Form.Item>
      {useDate && (
        <Form.Item label="노출 기간" name="exposeDate">
          <RangePicker />
        </Form.Item>
      )} */}
      <Form.Item
        label="댓글 허용 여부"
        name="commentYn"
        valuePropName="checked"
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item label="신품 가격" name="originalPrice">
        <InputNumber prefix="₩" style={{ width: '50%' }} />
      </Form.Item>
      <Form.Item label="미개봉 가격" name="unopenedPrice">
        <InputNumber prefix="₩" style={{ width: '50%' }} />
      </Form.Item>
      <Form.Item label="중고 가격" name="usedPrice">
        <InputNumber prefix="₩" style={{ width: '50%' }} />
      </Form.Item>
      <Form.Item label="중고 가격 조회 키워드" name="standardPriceKeyword">
        <Input />
      </Form.Item>
      <Form.Item
        label="문서 이미지 업로드"
        name="documentImgs"
        valuePropName="fileList"
      >
        <WikiUploadImg maxCount={5} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button htmlType="submit" type="primary">
            다음
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default WikiDataForm;
