/* eslint-disable react/jsx-no-comment-textnodes */
import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button, Input, Form, message } from 'antd';
import axios from 'axios';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import wikiLogo from '../asset/img/wiki/wikiLogo.svg';
import { WIKI_ADMIN_URL } from '../common/const';
import writeWikiIdToken from '../util/writeWikiIdToken';

interface LoginProps {
  setWikiIdToken: (data: any) => void;
  setAdminInfo: (data: any) => void;
}

const Login = ({ setWikiIdToken, setAdminInfo }: LoginProps): JSX.Element => {
  const [isLodding, setIsLoadding] = useState(false);

  const postLogin = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<any> => {
    const res = await axios.post(`${WIKI_ADMIN_URL}/login`, {
      username,
      password,
    });
    return res;
  };

  const onLogin = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<any> => {
    try {
      setIsLoadding(true);
      const loginRes = await postLogin({
        username,
        password,
      });
      const { AuthenticationResult, adminInfo } = loginRes.data.data;

      if (AuthenticationResult.IdToken) {
        const { IdToken } = AuthenticationResult;
        writeWikiIdToken(IdToken);
        setWikiIdToken(IdToken);
        setAdminInfo(adminInfo);
        sessionStorage.setItem('WikiAdminInfo', JSON.stringify(adminInfo));
        setIsLoadding(false);
      }
      return AuthenticationResult;
    } catch (error) {
      message.error(
        <>
          <span>로그인 실패</span>
          <br />
          <span>계속 실패한다면 IMS에서 로그인하시길 바랍니다.</span>
        </>,
      );
      setIsLoadding(false);
      return error;
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background: '#001529',
      }}
    >
      <div
        style={{
          margin: 'auto',
          paddingTop: '12%',
          width: 350,
        }}
      >
        <Form
          name="login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onLogin}
        >
          <img alt={wikiLogo} src={wikiLogo} style={{ width: '100%' }} />
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your IMS ID!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="IMS ID"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your IMS PASSWORD!' },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="IMS PASSWORD"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLodding}
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              GO
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
