/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Descriptions, Button, Skeleton, Card, Space, Tag } from 'antd';
import axios from 'axios';
import Slider from 'react-slick';
import _ from 'lodash';

import Image from '../components/web/common/image/Image';
import DescriptionImg from '../WikiDetail/WikiContent/WikiItemPerType/WikiItemDescription/DescriptionImg';
import DescriptionTable from '../WikiDetail/WikiContent/WikiItemPerType/WikiItemDescription/DescriptionTable';
import DescriptionText from '../WikiDetail/WikiContent/WikiItemPerType/WikiItemDescription/DescriptionText';
import DescriptionTextImg from '../WikiDetail/WikiContent/WikiItemPerType/WikiItemDescription/DescriptionTextImg';
import Comment from '../WikiComment/WikiComment';

import { WIKI_ADMIN_URL } from '../common/const';
import { getValidResourceUrl } from '../common/lib';

const WikiInfo = (): JSX.Element => {
  const { documentSeq }: { documentSeq?: number | string } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [wikiInfo, setWikiInfo] = useState<any>({});
  const [wikiItems, setWikiItems] = useState<any>({});
  const [wikiDescription, setWikiDescription] = useState<any>({});

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const getWikiDetail = async (): Promise<void> => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/wiki/detail?documentSeq=${documentSeq}`,
    );
    setWikiInfo(res.data.data);
    const documentItems = JSON.parse(res.data.data.wiki.documentItems);
    setWikiItems(documentItems);
    setWikiDescription(_.find(documentItems, { type: 'description' }));
    setIsLoading(false);
  };

  useEffect(() => {
    getWikiDetail();
  }, [documentSeq]);

  return (
    <>
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card style={{ width: '90%' }}>
          {isLoading ? (
            <>
              <Space>
                <Skeleton.Button active />
                <Skeleton.Avatar active />
                <Skeleton.Input active />
              </Space>
              <br />
              <br />
              <Skeleton.Button active />
              <br />
              <br />
              <Skeleton.Input active />
              <br />
              <Skeleton.Input active />
              <br />
              <br />
              <Skeleton.Image />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Skeleton.Image />
              <br />
              <br />
              <Skeleton.Input active />
              <br />
              <br />
              <Skeleton.Button active />
              <br />
              <br />
              <Skeleton.Input active />
              <br />
              <br />
              <Skeleton.Button active />
            </>
          ) : (
            <>
              <Descriptions
                title="위키 정보"
                bordered
                size="small"
                extra={
                  <Link to={`/wiki/update/${documentSeq}`}>
                    <Button type="primary">수정</Button>
                  </Link>
                }
                column={5}
              >
                {/* 기초 정보 */}
                <Descriptions.Item label="번호">{`${wikiInfo.wiki.documentSeq}`}</Descriptions.Item>
                <Descriptions.Item label="제목">{`${wikiInfo.wiki.documentTitle}`}</Descriptions.Item>
                <Descriptions.Item
                  label="카테고리"
                  span={3}
                >{`${wikiInfo.category
                  ?.map((item: any) => item.categoryName)
                  .join(' > ')}`}</Descriptions.Item>
                {/* 등록/수정 정보 */}
                <Descriptions.Item label="등록자">{`${wikiInfo.wiki.regName}`}</Descriptions.Item>
                <Descriptions.Item label="등록일">{`${wikiInfo.wiki.regDate}`}</Descriptions.Item>
                <Descriptions.Item label="수정자">{`${wikiInfo.wiki.updateName}`}</Descriptions.Item>
                <Descriptions.Item label="수정일">{`${wikiInfo.wiki.updateDate}`}</Descriptions.Item>
                <Descriptions.Item label="조회수">{`${
                  wikiInfo.statistics[0]?.totalClick || '-'
                }`}</Descriptions.Item>
                {/* 가격 정보 */}
                <Descriptions.Item label="신품 가격">{`${
                  wikiInfo.wiki.originalPrice
                    ? `${wikiInfo.wiki.originalPrice}원`
                    : '-'
                }`}</Descriptions.Item>
                <Descriptions.Item label="미개봉 가격">{`${
                  wikiInfo.wiki.unopenedPrice
                    ? `${wikiInfo.wiki.unopenedPrice}원`
                    : '-'
                }`}</Descriptions.Item>
                <Descriptions.Item label="중고 가격">{`${
                  wikiInfo.wiki.usedPrice ? `${wikiInfo.wiki.usedPrice}원` : '-'
                }`}</Descriptions.Item>
                <Descriptions.Item label="시세 조회 키워드" span={3}>{`${
                  wikiInfo.wiki.standardPriceKeyword || '-'
                }`}</Descriptions.Item>
                {/* 개요 */}
                <Descriptions.Item
                  label="개요"
                  span={5}
                >{`${wikiInfo.wiki.documentDesc}`}</Descriptions.Item>
                <Descriptions.Item label="이미지" span={5}>
                  {wikiInfo.image.length > 0 ? (
                    <div
                      style={{
                        maxWidth: '800px',
                        maxHeight: '800px',
                        marginBottom: '50px',
                      }}
                    >
                      <Slider className="slide-wrap" {...settings}>
                        {wikiInfo.image.length > 0 &&
                          wikiInfo.image.map((img: any, index: number) => {
                            let imgUrl = '';
                            if (typeof img === 'string') {
                              imgUrl = img;
                            } else if (img.originFileObj) {
                              imgUrl = URL.createObjectURL(img.originFileObj);
                            } else {
                              imgUrl = img.documentImgUrl;
                            }
                            return (
                              <div key={`상품이미지-${index}`}>
                                <Image
                                  src={getValidResourceUrl(imgUrl, 'wikiImage')}
                                  alt={`상품이미지-${index}`}
                                  className="wikiImg"
                                />
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="상세" span={5}>
                  {wikiDescription ? (
                    <div className="description-item-wrap">
                      {wikiDescription.data?.map((props: any) => {
                        switch (props.type) {
                          case 'text':
                            return (
                              <>
                                <DescriptionText
                                  key={props.type}
                                  data={props.data}
                                />
                                <br />
                              </>
                            );
                          case 'image':
                            return (
                              <>
                                <DescriptionImg
                                  key={props.type}
                                  data={props.data}
                                />
                                <br />
                              </>
                            );
                          case 'table':
                            return (
                              <>
                                <DescriptionTable
                                  key={props.type}
                                  data={props.data}
                                />
                                <br />
                              </>
                            );
                          case 'text/image':
                            return (
                              <>
                                <DescriptionTextImg
                                  key={props.type}
                                  data={props.data}
                                />
                                <br />
                              </>
                            );
                          default:
                            return <></>;
                        }
                      })}
                    </div>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="관련 문서">
                  {wikiInfo.relatedDocuments.map((item: any) => {
                    return (
                      <>
                        <Link
                          to={`/wiki/info/${item.documentSeq}`}
                        >{`${item.documentSeq}. ${item.documentTitle}`}</Link>
                        <br />
                      </>
                    );
                  })}
                </Descriptions.Item>
                <Descriptions.Item label="관련 상품 키워드">
                  {
                    _.find(wikiItems, { type: 'relatedProducts' })?.data
                      .searchKeyword
                  }
                </Descriptions.Item>
              </Descriptions>
              <br />
              {/* 태그 */}
              <Descriptions
                bordered
                size="small"
                column={1}
                labelStyle={{ width: '100px' }}
              >
                <Descriptions.Item label="라벨">
                  {wikiInfo.label.map((label: any) => {
                    return <Tag key={label.labelSeq}>{label.labelName}</Tag>;
                  })}
                </Descriptions.Item>
                <Descriptions.Item label="태그">
                  {wikiInfo.tag.map((tag: any) => {
                    return <Tag key={tag.tagSeq}>{tag.tagName}</Tag>;
                  })}
                </Descriptions.Item>
                <Descriptions.Item label="제외 태그">
                  {wikiInfo.excludeTag.map((excludeTag: any) => {
                    return (
                      <Tag key={excludeTag.excludeTagSeq}>
                        {excludeTag.excludeTagName}
                      </Tag>
                    );
                  })}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          <Comment documentSeq={documentSeq} />
        </Card>
      </div>
    </>
  );
};
export default WikiInfo;
