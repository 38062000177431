import { Form, Input, DatePicker, Switch, Button, InputNumber } from 'antd';
import React, { ReactElement, useState } from 'react';
import WikiUploadImg from './WikiUploadImg';
import WikiCategorySelect from './WikiCategorySelect';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
interface WikiDataFormProps {
  visible: boolean;
  nextStep: (data: any) => void;
}

const WikiDataForm: React.FC<WikiDataFormProps> = ({ visible, nextStep }) => {
  // const [useDate, setUseDate] = useState(false);
  const [wikiDocumentImageList, setWikiDocumentImageList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    nextStep(values);
  };

  return (
    <Form
      style={{
        display: visible ? 'block' : 'none',
        width: '80%',
      }}
      {...formItemLayout}
      form={form}
      name="위키 문서 정보"
      onFinish={onFinish}
      onValuesChange={(changedValues, allValues) => {
        // console.log('onValuesChange: ', allValues);
      }}
      initialValues={{}}
      scrollToFirstError
    >
      <Form.Item
        name="documentCategories"
        label="카테고리"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <WikiCategorySelect />
      </Form.Item>

      <Form.Item
        name="documentTitle"
        label="제목"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="documentDesc"
        label="개요"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* <Form.Item
        label="노출 기간 제한"
        name="unlimitedYn"
        valuePropName="checked"
      >
        <Switch onChange={setUseDate} />
      </Form.Item>
      {useDate && (
        <Form.Item label="노출 기간" name="exposeDate">
          <RangePicker />
        </Form.Item>
      )} */}

      {/* 일단 false로 */}
      {/* <Form.Item name="노출 여부" label="Switch" valuePropName="checked">
        <Switch />
      </Form.Item> */}

      <Form.Item
        label="댓글 허용 여부"
        name="commentYn"
        valuePropName="checked"
      >
        <Switch defaultChecked />
      </Form.Item>

      <Form.Item label="신품 가격" name="originalPrice">
        <InputNumber prefix="₩" style={{ width: '50%' }} />
      </Form.Item>

      <Form.Item label="미개봉 가격" name="unopenedPrice">
        <InputNumber prefix="₩" style={{ width: '50%' }} />
      </Form.Item>

      <Form.Item label="중고 가격" name="usedPrice">
        <InputNumber prefix="₩" style={{ width: '50%' }} />
      </Form.Item>

      <Form.Item label="중고 가격 조회 키워드" name="standardPriceKeyword">
        <Input />
      </Form.Item>

      <Form.Item label="문서 이미지 업로드" name="documentImgs">
        <WikiUploadImg onChange={setWikiDocumentImageList} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button htmlType="submit" type="primary">
            다음
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default WikiDataForm;
