import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload, Collapse } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as xlsx from 'xlsx';

import DescriptionTable from '../WikiDetail/WikiContent/WikiItemPerType/WikiItemDescription/DescriptionTable';

const { Panel } = Collapse;

interface WikiTableFormProps {
  formId: string;
  initialValues: any;
  onChange: (id: any, data: any) => void;
}

const WikiTableForm: React.FC<WikiTableFormProps> = ({
  formId,
  initialValues,
  onChange,
}) => {
  const [tableTitle, setTableTitle] = useState('');
  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    if (initialValues) {
      setTableTitle(initialValues.data.title);
      setTableData({
        columns: initialValues.data.columns,
        contents: initialValues.data.contents,
      });
    }
  }, [initialValues]);

  const onChangeTitle = (title: any) => {
    setTableTitle(title);
    const table = {
      type: 'table',
      data: {
        title, // stirng
        ...tableData, // columns, contents
      },
    };
    onChange(formId, table);
  };

  const onChangeContent = (data: any) => {
    setTableData(data);
    const table = {
      type: 'table',
      data: {
        title: tableTitle, // stirng
        ...data, // columns, contents
      },
    };
    onChange(formId, table);
  };

  const createWikiTableData = (array: any) => {
    let columns: any[] = [];
    const contents: any[] = [];
    array.forEach((item: any, index: any) => {
      if (item[0] === '표-컬럼' || item[0] === 'column') {
        item.shift();
        columns = item;
      } else if (item[0] === '표' || item[0] === 'row') {
        item.shift();
        contents.push(item);
      }
    });
    return { columns, contents };
  };

  const onload = (event: any) => {
    const file = event.target.result;
    const workBook = xlsx.read(file, { type: 'array', cellDates: true });
    // 1번 시트만 사용
    const sheetName = workBook.SheetNames[0];
    const sheet = workBook.Sheets[sheetName];
    // header : 1 옵션은 데이터를 2중 배열로 받겠다는 의미
    const data = xlsx.utils.sheet_to_json(sheet, { header: 1 });
    const table = createWikiTableData(data);
    onChangeContent(table);
    return table;
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    const reader = new FileReader();
    reader.onload = onload;
    reader.readAsArrayBuffer(file);
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <Form
      name="테이블"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      initialValues={{
        title: initialValues?.data.title,
        contents: {
          ...initialValues?.data,
        },
      }}
      onValuesChange={(changedValues: any, allValues) => {
        // console.log('allValues', allValues);
      }}
    >
      <Form.Item
        label="제목"
        name="title"
        rules={[{ required: true, message: '항목의 제목을 입력해주세요' }]}
      >
        <Input
          onChange={(event) => {
            onChangeTitle(event.target.value);
          }}
        />
      </Form.Item>

      <Form.Item label="표 엑셀 파일" name="contents">
        <Upload customRequest={dummyRequest} maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
      {tableData && (
        <Collapse>
          <Panel header="입력 데이터" key="1">
            <DescriptionTable
              data={{ title: tableTitle || '', ...tableData }}
            />
          </Panel>
        </Collapse>
      )}
    </Form>
  );
};

export default WikiTableForm;
