import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { getWonUnit } from '../../../common/parser';

import { palette } from '../../../lib/styles/palette';

interface WikiPriceProps {
  title: string;
  price: number | null;
}

function WikiPrice({ title, price }: WikiPriceProps): ReactElement | null {
  if (price === null) return null;
  return (
    <div css={wrap}>
      <div className="price-tag">{title}</div>
      {price && <div className="price-content">{getWonUnit(price)}</div>}
    </div>
  );
}

const wrap = css`
  display: flex;
  justify-content: space-between;
  font-size: 17px;

  :first-of-type {
    margin-bottom: 12px;
  }
  .price-content {
    font-size: inherit;
    font-weight: 400;
    color: ${palette.gray6};
  }
`;

export default WikiPrice;
