/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Divider, message } from 'antd';

import { css } from '@emotion/react';

import mock from '../WikiDetail/mockData.json';

import WikiContent from '../WikiDetail/WikiContent';
import WikiTitle from '../WikiDetail/WikiTitle';

import updateWiki from './updateWiki';

interface WikiThumbnailProps {
  wiki?: any;
  nextStep: (data: any) => void;
  prevStep: () => void;
}

const WikiThumbnail: React.FC<WikiThumbnailProps> = ({
  wiki = mock,
  nextStep,
  prevStep,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    data: {
      documentImgs,
      documentLabels,
      documentCategories,
      document,
      documentItems,
    },
  } = wiki;

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginTop: '10px',
            float: 'left',
            paddingLeft: '5%',
          }}
        >
          미리보기
        </div>
        <div
          style={{
            marginTop: '10px',
            float: 'right',
            paddingRight: '5%',
          }}
        >
          <Button
            onClick={() => {
              prevStep();
            }}
            style={{ marginRight: '10px' }}
          >
            이전
          </Button>

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                const res = await updateWiki(wiki.data);
                setIsLoading(false);
                nextStep(res);
              } catch (error) {
                message.error(`생성에 실패했습니다.\n${error}`);
              }
            }}
          >
            확정
          </Button>
        </div>
      </div>
      <Divider />
      <div css={wrapper}>
        <WikiTitle
          document={document}
          documentCategories={documentCategories}
          documentLabels={documentLabels}
        />
        <WikiContent
          document={document}
          documentCategories={documentCategories}
          documentImgs={documentImgs}
          documentItems={documentItems}
        />
      </div>
    </div>
  );
};

const wrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  margin: 0 auto;
  background-color: white;
  -webkit-touch-callout: none;
  line-height: 1;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-weight: 400;
`;

export default WikiThumbnail;
