import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { getValidResourceUrl } from '../../../../../common/lib';

import { palette } from '../../../../../lib/styles/palette';
import SubTitle from '../../../../common/SubTitle';

interface DescriptionImgProps {
  data: {
    title?: string;
    imageUrl: any;
  };
}

function DescriptionImg({
  data: { title, imageUrl },
}: DescriptionImgProps): ReactElement {
  let imgUrl = '';
  if (typeof imageUrl === 'string') {
    imgUrl = imageUrl;
  } else if (imageUrl.originFileObj) {
    imgUrl = URL.createObjectURL(imageUrl.originFileObj);
  } else {
    imgUrl = imageUrl.url;
  }
  return (
    <div css={wrap}>
      {title && <SubTitle>{title}</SubTitle>}
      <div className="content">
        <img
          src={getValidResourceUrl(imgUrl, 'wikiImage')}
          alt={`${title}_이미지`}
        />
      </div>
    </div>
  );
}

export default DescriptionImg;

const wrap = css`
  padding: 0 20px;
  font-size: 15px;
  line-height: 23px;
  white-space: pre-wrap;

  .content {
    color: ${palette.gray6};
    img {
      width: 100%;
    }
  }
`;
