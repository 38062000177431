import { ReactNode } from 'react';

import useEmblaCarousel from 'embla-carousel-react';

import { css } from '@emotion/react';

interface TypeCarousel {
  children: ReactNode;
}

const EmblaCarousel = ({ children }: TypeCarousel) => {
  const [viewportRef] = useEmblaCarousel({
    dragFree: true,
    containScroll: 'trimSnaps',
    align: 'start',
  });

  return (
    <div css={carousel}>
      <div
        className="carousel-viewport"
        css={carouselViewport}
        ref={viewportRef}
      >
        <div css={carouselContainer}>{children}</div>
      </div>
    </div>
  );
};

const carousel = css`
  position: relative;
`;

const carouselViewport = css`
  overflow: hidden;
  width: 100%;
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
  &.is-dragging {
    cursor: grabbing;
  }
`;

const carouselContainer = css`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  & > div {
    padding-right: 20px;
    &:last-of-type {
      padding-right: 0;
    }
  }
`;

export default EmblaCarousel;
