import React, { useEffect, useState } from 'react';
import { Steps, Card, Result, PageHeader } from 'antd';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import WikiDescription from './WikiDescription';
import WikiDataForm from './WikiDataForm';
import WikiRelateForm from './WikiRelateForm';
import WikiThumbnail from './WikiThumbnail';
import { WIKI_ADMIN_URL } from '../common/const';

const { Step } = Steps;

const WikiUpdate = (): JSX.Element => {
  const { documentSeq = 0 } = useParams();
  const [wiki, setWiki] = useState<any>();
  const [currentStep, setCurrentStep] = useState(0);
  const [wikiData, setWikiData] = useState<any>({});
  const [wikiDescription, setWikiDescription] = useState<any>({});
  const [wikiRelated, setWikiRelated] = useState<any>({});
  const [createdWiki, setCreatedWiki] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [wikiInfo, setWikiInfo] = useState<any>({});
  const [wikiItems, setWikiItems] = useState<any>({});
  // const [wikiDescription, setWikiDescription] = useState<any>({});

  const getWikiDetail = async (): Promise<void> => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/wiki/detail?documentSeq=${documentSeq}`,
    );
    const { data } = res.data;
    const documentItems = JSON.parse(res.data.data.wiki.documentItems);
    data.documentItems = documentItems;
    setWikiItems(documentItems);
    setWikiInfo(data);
    setWikiDescription(_.find(documentItems, { type: 'description' }));
    setIsLoading(false);
  };

  useEffect(() => {
    getWikiDetail();
  }, [documentSeq]);

  const nextStep = (): void => {
    setCurrentStep(currentStep + 1);
  };
  const prevStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  const generateWikiData = (data: any) => {
    setWikiData(data);
    setCurrentStep(currentStep + 1);
  };

  const generateWikiDescription = (data: any) => {
    setWikiDescription(data);
    setCurrentStep(currentStep + 1);
  };

  const generateWikiRelated = (data: any) => {
    setWikiRelated(data);
    generateWiki({
      data: wikiData,
      description: wikiDescription,
      related: data,
    });
  };

  const generateWiki = ({ data, description, related }: any) => {
    const tempWiki: any = {
      data: {
        documentImgs: data.documentImgs || [],
        documentLabels: related.label,
        documentCategories: data.documentCategories,
        document: {
          documentSeq: +documentSeq,
          documentTitle: data.documentTitle,
          documentDesc: data.documentDesc,
          originalPrice: data.originalPrice,
          unopenedPrice: data.unopenedPrice,
          usedPrice: data.usedPrice,
          standardPriceKeyword: data.standardPriceKeyword,
        },
        documentItems: [description],
      },
    };
    if (related.relatedDocuments) {
      tempWiki.data.documentItems.push(related.relatedDocuments);
    }
    if (related.relatedProducts) {
      tempWiki.data.documentItems.push(related.relatedProducts);
    }
    if (related.label) {
      tempWiki.data.label = related.label;
    }
    if (related.tag) {
      tempWiki.data.tag = related.tag;
    }
    if (related.excludeTag) {
      tempWiki.data.excludeTag = related.excludeTag;
    }

    setWiki(tempWiki);
    setCurrentStep(currentStep + 1);
    return '';
  };

  return (
    <>
      <PageHeader className="wiki-comment-header" title="문서 수정" />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Steps
          current={currentStep}
          style={{ width: '40vw', marginTop: '20px', marginBottom: '20px' }}
        >
          <Step
            title="문서 정보"
            description="문서의 기본 정보를 입력해주세요."
          />
          <Step
            title="문서 세부"
            description="문서 세부 정보를 입력해주세요."
          />
          <Step title="관련 문서" description="관련된 정보를 입력해 주세요." />
        </Steps>
        {!isLoading && wikiInfo ? (
          <Card
            style={{
              width: '80%',
            }}
          >
            <WikiDataForm
              initialValues={wikiInfo}
              visible={currentStep === 0}
              nextStep={generateWikiData}
            />
            <WikiDescription
              initialValues={wikiInfo}
              visible={currentStep === 1}
              nextStep={generateWikiDescription}
              prevStep={prevStep}
            />
            <WikiRelateForm
              initialValues={wikiInfo}
              visible={currentStep === 2}
              nextStep={generateWikiRelated}
              prevStep={prevStep}
            />
            {currentStep === 3 && wiki ? (
              <WikiThumbnail
                wiki={wiki}
                nextStep={(data) => {
                  setCreatedWiki(data);
                  nextStep();
                }}
                prevStep={prevStep}
              />
            ) : null}
            {currentStep === 4 && createdWiki ? (
              <Result status="success" title="위키가 수정되었습니다." />
            ) : null}
          </Card>
        ) : (
          <div />
        )}
      </div>
    </>
  );
};
export default WikiUpdate;
