import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Pagination, Select, PageHeader, Tag, Switch } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import axios from 'axios';

import { WIKI_ADMIN_URL } from '../common/const';

const { Option } = Select;

interface CommentType {
  key: string;
  commentSeq: number;
  commentContent: string;
  parentCommentSeq: number;
  parentCommentContent: string;
  documentSeq: number;
  documentTitle: string;
  commentTypeCode: string;
  commentStatus: string;
  storeSeq: number;
  nickName: string;
  regDate: string;
}

interface WikiCommentProps {
  documentSeq?: number | string;
}

const Comment: React.FC<WikiCommentProps> = ({
  documentSeq: propsDocumentSeq,
}) => {
  const [commentList, setCommentList] = useState<CommentType[]>([]);
  const [commentCount, setCommentCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const columns: ColumnsType<CommentType> = [
    {
      title: '번호',
      dataIndex: 'commentSeq',
      key: 'commentSeq',
      width: 90,
    },
    {
      title: '타입',
      dataIndex: 'commentTypeCode',
      key: 'commentTypeCode',
      width: 100,
      render: (commentTypeCode: string) => {
        switch (commentTypeCode) {
          case 'NORMAL':
            return <Tag color="default">일반</Tag>;
          case 'TIP':
            return <Tag color="blue">팁</Tag>;
          case 'ASK':
            return <Tag color="green">문의</Tag>;
          case 'REPLY':
          default:
            return <Tag color="default">댓글</Tag>;
        }
      },
    },
    {
      title: '상태',
      dataIndex: 'commentStatus',
      key: 'commentStatus',
      width: 100,
      render: (commentStatus: string) => {
        switch (commentStatus) {
          case 'NORMAL':
            return <Tag color="default">일반</Tag>;
          case 'USER_DEL':
            return <Tag color="red">사용자삭제</Tag>;
          case 'ADMIN_HIDDEN':
            return <Tag color="blue">숨김</Tag>;
          default:
            return commentStatus;
        }
      },
    },
    {
      title: '문서',
      dataIndex: 'documentSeq',
      key: 'documentSeq',
      width: 240,
      render: (documentSeq: number, record: any) => {
        return (
          <Link
            to={`/wiki/info/${documentSeq}`}
          >{`${documentSeq}. ${record.documentTitle}`}</Link>
        );
      },
    },
    {
      title: '내용',
      dataIndex: 'commentContent',
      key: 'commentContent',
      width: '25%',
    },
    {
      title: '상위 정보',
      dataIndex: 'parentCommentSeq',
      key: 'parentCommentSeq',
      width: 400,
      render: (parentCommentSeq: number, record: any) => {
        return parentCommentSeq
          ? `${parentCommentSeq}. ${record.parentCommentContent}`
          : '';
      },
    },
    {
      title: '등록자',
      dataIndex: 'nickName',
      key: 'nickName',
      width: 180,
    },
    {
      title: '가게 번호',
      dataIndex: 'storeSeq',
      key: 'storeSeq',
      width: 120,
    },
    {
      title: '등록일',
      dataIndex: 'regDate',
      key: 'regDate',
      width: 180,
    },
    {
      title: '숨기기',
      dataIndex: 'commentSeq',
      key: 'commentSeq',
      width: 100,
      render: (commentSeq: number, record: any, index: number) => {
        return record.commentStatus !== 'USER_DEL' ? (
          <Switch
            checked={record.commentStatus === 'ADMIN_HIDDEN'}
            onChange={() => {
              onChangeStatus({
                commentSeq,
                commentStatus:
                  record.commentStatus === 'ADMIN_HIDDEN'
                    ? 'NORMAL'
                    : 'ADMIN_HIDDEN',
                index,
              });
            }}
          />
        ) : (
          '-'
        );
      },
    },
  ];

  useEffect(() => {
    getCommentList({});
  }, [propsDocumentSeq]);

  const onChangePage = (page: number, size: number) => {
    getCommentList({ page, size });
  };

  const onChangeStatus = async ({
    commentSeq,
    commentStatus,
    index,
  }: {
    commentSeq: number;
    commentStatus: string;
    index: number;
  }) => {
    const res = await axios.patch(`${WIKI_ADMIN_URL}/comment`, {
      commentSeq,
      commentStatus,
    });
    const newWikiList = [...commentList];
    newWikiList[index].commentStatus = commentStatus;
    setCommentList(newWikiList);
  };

  const getCommentList = async ({
    page = 1,
    size = 10,
  }: {
    page?: number;
    size?: number;
  }) => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/comment?page=${page}&size=${size}${
        propsDocumentSeq ? `&documentSeq=${propsDocumentSeq}` : ''
      }`,
    );
    const { rows, count } = res.data.data;
    setCommentList(rows);
    setCommentCount(count);
    setIsLoading(false);
    return rows;
  };

  return (
    <>
      <PageHeader className="wiki-comment-header" title="댓글 목록" />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: '90%',
        }}
      >
        <Table
          style={{ width: '100%' }}
          loading={isLoading}
          columns={columns}
          dataSource={commentList}
          pagination={false}
          // scroll={{ x: 1750, y: 1000 }}
        />
        <CommentPagination total={commentCount} onChange={onChangePage} />
      </div>
    </>
  );
};
export default Comment;

const CommentPagination = ({
  total = 10,
  onChange = () => {},
}: {
  total?: number;
  onChange?: (page: number, size: number) => void;
}): JSX.Element => {
  const [curruntPage, setCurruntPage] = useState<number>(1);

  const onClickPage = (page: number, size: number) => {
    setCurruntPage(page);
    onChange(page, size);
  };

  return (
    <>
      <Pagination
        style={{ marginTop: '20px', width: '100%', textAlign: 'end' }}
        current={curruntPage}
        total={total}
        onChange={onClickPage}
        pageSizeOptions={['10', '20', '50']}
      />
    </>
  );
};
