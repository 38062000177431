import { Button, Upload } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import './WikiUploadImg.css';
import { WIKI_IMG_URL } from '../common/const';

interface WikiUploadImgProps {
  fileList?: any;
  maxCount?: number;
  onChange?: (data: any) => void;
}

const WikiUploadImg: React.FC<WikiUploadImgProps> = ({
  fileList = [],
  onChange: propsOnChange,
  maxCount = 10,
}) => {
  const [defaultFileList, setDefaultFileList] = useState(fileList);

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onChange = (info: any): void => {
    if (propsOnChange) propsOnChange(info?.fileList);
  };

  return (
    <Upload
      accept="image/*"
      className="upload-list-inline"
      listType="picture"
      defaultFileList={defaultFileList}
      customRequest={dummyRequest}
      onChange={onChange}
      maxCount={maxCount}
    >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};

export default WikiUploadImg;
