import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import Icon from '../../components/common/Icon';

import { palette } from '../../lib/styles/palette';

interface SubTitleProps {
  children: string;
  to?: string;
  onClick?: () => void;
}

function SubTitle({
  children: title,
  to,
  onClick,
}: SubTitleProps): ReactElement {
  return (
    <div css={wrap}>
      <div>{title}</div>
      {to && (
        <Link to={to}>
          <Icon icon="arrowRight" size="18px" stroke={palette.gray5} />
        </Link>
      )}
      {onClick && (
        <button onClick={onClick}>
          <Icon icon="arrowRight" size="18px" stroke={palette.gray5} />
        </button>
      )}
    </div>
  );
}

const wrap = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  white-space: pre-wrap;

  a {
    display: flex;
    cursor: pointer;
  }

  button {
    background: transparent;
  }
`;

export default SubTitle;
