import { ReactElement, useEffect, useState } from 'react';

import { Table } from 'antd';

import { css } from '@emotion/react';

import { DescTable } from '../../../../../@type/webView/wiki';

import SubTitle from '../../../../common/SubTitle';
import useTableHook from './useTableHook';

interface DesciprionTableProps {
  data: DescTable;
}

function DescriptionTable({
  data: { title, columns, contents },
}: DesciprionTableProps): ReactElement {
  const [isShowBaseColumn, setIsShowBaseColumn] = useState(true);
  const { getBaseColumnObjArr, getContentsObjArr } = useTableHook();

  const COLUMN_CNT = contents[0].length;
  const BASE_COLUMN_CNT = columns.length;

  useEffect(() => {
    setIsShowBaseColumn(BASE_COLUMN_CNT > 0);
  }, [BASE_COLUMN_CNT]);

  return (
    <div css={wrap}>
      {title && <SubTitle>{title}</SubTitle>}
      <Table
        columns={getBaseColumnObjArr(columns, COLUMN_CNT)}
        dataSource={getContentsObjArr(contents)}
        scroll={{ x: 'max-content' }}
        pagination={false}
        css={tableStyle(isShowBaseColumn)}
      />
    </div>
  );
}

export default DescriptionTable;

const wrap = css`
  padding: 0 20px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 23px;
  white-space: pre-wrap;
`;

const tableStyle = (isShowBaseColumn: boolean) => css`
  table {
    border: ${isShowBaseColumn ? '' : `1px solid #f0f0f0`};
    border-bottom: none;
    tbody {
      tr {
        td {
          word-break: keep-all;
          text-overflow: ellipsis;
          :first-of-type {
            background-color: #fafafa;
          }
        }
      }
    }
    .ant-table-thead {
      display: ${isShowBaseColumn ? '' : 'none'};
    }
  }
`;
