import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Button, Select, Popover, message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';

import WikiDescriptionSelector from './WikiDescriptionSelector';
import WikiTextForm from './WikiTextForm';
import WikiImageForm from './WikiImageForm';
import WikiTableForm from './WikiTableForm';

const { Option } = Select;

interface WikiDescriptionProps {
  initialValues: any;
  visible: boolean;
  nextStep: (data: any) => void;
  prevStep: () => void;
}

interface Item {
  id: string;
  formType: string;
}

interface WikiItem {
  type: string | null;
  data: any | null;
}

// fake data generator
const getItems = (count: number): Item[] =>
  Array.from({ length: count }, (value, key) => key).map((key) => ({
    id: `${key}`,
    formType: `${key}`,
  }));

// a little function to help us with reordering the result
const reorder = (
  list: Item[],
  startIndex: number,
  endIndex: number,
): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: `3px`,

  // change background colour if dragging
  background: 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
});

const itemType: { [key: string]: string } = {
  table: '표',
  text: '텍스트',
  image: '이미지',
  imageText: '이미지/텍스트',
};

const WikiDescription: React.FC<WikiDescriptionProps> = ({
  initialValues,
  visible,
  nextStep,
  prevStep,
}) => {
  const [formList, setFormList] = useState<Item[]>(getItems(0));
  const [descriptionData, setDescriptionData] = useState<any>({});
  const [selectorVisible, setSelectorVisible] = useState(false);

  useEffect(() => {
    const itemsData = initialValues.documentItems.find(
      (item: any) => item.type === 'description',
    )?.data;

    const form = itemsData.map((item: any, index: number) => {
      return { id: `${index}`, formType: item.type };
    });
    setFormList(form);

    const descData = {} as any;
    itemsData.forEach((item: any, index: number) => {
      descData[index] = item;
    });
    setDescriptionData(descData);
  }, [initialValues]);

  const addForm = (formType: string) => {
    const newFormList = [...formList, { id: `${Date.now()}`, formType }];
    setFormList(newFormList);
  };

  const deleteForm = (id: any) => {
    const newFormList = _.remove(formList, (item) => item.id !== id);
    delete descriptionData[id];
    setFormList(newFormList);
  };

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: Item[] = reorder(
      formList,
      result.source.index,
      result.destination.index,
    );

    setFormList(items);
  };

  const onDataChange = (id: any, data: any) => {
    const newData = { ...descriptionData, [id]: data };
    setDescriptionData(newData);
  };

  const checkData = (form: any) => {
    if (descriptionData[form.id]) {
      const {
        type,
        data: { title, contents, imageUrl, colunms },
      } = descriptionData[form.id];
      if (type === 'text') {
        if (!title || !contents) throw new Error();
      } else if (type === 'image') {
        if (!title || !imageUrl) throw new Error();
      } else if (type === 'table') {
        if (!title || !contents) throw new Error();
      } else if (type === 'imageText') {
        if (!title || !imageUrl || !contents) throw new Error();
      }
    } else {
      throw new Error();
    }
  };

  const onSubmit = () => {
    const data: WikiItem = {
      type: 'description',
      data: [],
    };
    formList.forEach((form, index) => {
      try {
        checkData(form);
        data.data.push({
          type: form.formType,
          data: descriptionData[form.id].data,
        });
      } catch (error) {
        message.error(`${index + 1}번 데이터 오류`);
        throw new Error(`${index + 1}번 데이터 오류`);
      }
    });
    nextStep(data);
  };

  return (
    <div
      style={{
        display: visible ? 'block' : 'none',
        width: '100%',
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot): JSX.Element => {
            return (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                문서 항목
                {formList.map((item: Item, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <div style={{ display: 'flow-root' }}>
                          <div
                            style={{
                              float: 'left',
                              fontSize: '20px',
                              paddingTop: 0,
                            }}
                          >
                            {itemType[item.formType]}
                          </div>
                          <div style={{ float: 'right' }}>
                            <CloseOutlined
                              style={{ fontSize: '20px', color: '#AAAAAA' }}
                              onClick={() => {
                                deleteForm(item.id);
                              }}
                            />
                          </div>
                        </div>
                        {item.formType === 'text' && (
                          <WikiTextForm
                            formId={item.id}
                            initialValues={descriptionData[item.id]}
                            onChange={onDataChange}
                          />
                        )}
                        {item.formType === 'image' && (
                          <WikiImageForm
                            formId={item.id}
                            initialValues={descriptionData[item.id]}
                            onChange={onDataChange}
                          />
                        )}
                        {item.formType === 'table' && (
                          <WikiTableForm
                            formId={item.id}
                            initialValues={descriptionData[item.id]}
                            onChange={onDataChange}
                          />
                        )}
                        {item.formType === 'imageText' && <div />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            );
          }}
        </Droppable>
        <Popover
          content={
            <WikiDescriptionSelector
              onSelect={(event) => {
                addForm(event);
                setSelectorVisible(false);
              }}
            />
          }
          trigger="click"
          visible={selectorVisible}
          onVisibleChange={() => setSelectorVisible(!selectorVisible)}
        >
          <Button type="dashed" block icon={<PlusOutlined />}>
            문서 항목 추가
          </Button>
        </Popover>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              prevStep();
            }}
            style={{ marginRight: '10px' }}
          >
            이전
          </Button>

          <Button
            type="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            다음
          </Button>
        </div>
      </DragDropContext>
    </div>
  );
};

export default WikiDescription;
