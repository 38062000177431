import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

const { TextArea } = Input;

interface WikiTextFormProps {
  formId: string;
  onChange: (id: any, data: any) => void;
}

const WikiTextForm: React.FC<WikiTextFormProps> = ({ formId, onChange }) => {
  const onValuesChange = (changedValues: any, allValues: any) => {
    onChange(formId, {
      type: 'text',
      data: allValues,
    });
  };

  return (
    <Form
      name="텍스트"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onValuesChange={onValuesChange}
      autoComplete="off"
    >
      <Form.Item
        label="제목"
        name="title"
        rules={[{ required: true, message: '항목의 제목을 입력해주세요' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="내용"
        name="contents"
        rules={[{ required: true, message: '항목의 내용을 입력해주세요' }]}
      >
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  );
};

export default WikiTextForm;
