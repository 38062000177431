import React, { useEffect, useState } from 'react';
import { Table, Pagination, PageHeader, Switch, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import _ from 'lodash';

import CategoryModal from './CategoryModal';

import { WIKI_ADMIN_URL } from '../common/const';

interface CategoryType {
  key: string;
  name: string;
  documentSeq: number;
  categoryName: string;
  parentsCategoryName: string;
  documentTitle: string;
  documentDesc: string;
  regName: string;
  regDate: string;
  useYn: number;
}

const Category = (): JSX.Element => {
  const [categoryList, setCategoryList] = useState<CategoryType[]>([]);
  const [categoryCount, setCategoryCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const columns: ColumnsType<CategoryType> = [
    {
      title: '상위 카테고리',
      dataIndex: 'parentsCategorySeq',
      key: 'parentsCategorySeq',
      render: (parentsCategorySeq: number, record: CategoryType) => {
        return `${parentsCategorySeq}. ${
          record.parentsCategoryName || '최상위'
        }`;
      },
    },
    {
      title: '카테고리',
      dataIndex: 'categorySeq',
      key: 'categorySeq',
      render: (categorySeq: number, record: CategoryType) => {
        return `${categorySeq}. ${record.categoryName}`;
      },
    },
    {
      title: '순서',
      dataIndex: 'categorySort',
      key: 'categorySort',
    },
    {
      title: '단계',
      dataIndex: 'categoryDepth',
      key: 'categoryDepth',
    },
    {
      title: '등록 문서',
      dataIndex: 'documentCount',
      key: 'documentCount',
      render: (documentCount: number) => {
        return `${documentCount}건`;
      },
    },
    {
      title: '사용 여부',
      dataIndex: 'useYn',
      key: 'useYn',
      width: 120,
      render: (useYn: number, record: any, index: number) => {
        return (
          <Switch
            checked={useYn === 1}
            onChange={() => {
              changeUse({
                categorySeq: record.categorySeq,
                useYn: useYn ? 0 : 1,
                index,
              });
            }}
          />
        );
      },
    },
    {
      title: '등록자',
      dataIndex: 'regAdminName',
      key: 'regAdminName',
      width: 180,
    },
    {
      title: '등록일',
      dataIndex: 'regDate',
      key: 'regDate',
      width: 180,
    },
    {
      title: '변경자',
      dataIndex: 'updateAdminName',
      key: 'regDate',
      width: 180,
    },
    {
      title: '변경일',
      dataIndex: 'updateDate',
      key: 'reg_admin_name',
      width: 180,
    },
  ];

  useEffect(() => {
    getCategoryList({});
  }, []);

  const onChangePage = (page: number, size: number) => {
    getCategoryList({ page, size });
  };

  const getCategoryList = async ({
    page = 1,
    size = 10,
  }: {
    page?: number;
    size?: number;
  }) => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/category/list?page=${page}&size=${size}`,
    );
    const { rows, count } = res.data.data;
    setCategoryList(rows);
    setCategoryCount(count);
    setIsLoading(false);
    return rows;
  };

  const changeUse = async ({ categorySeq, useYn, index }: any) => {
    const res = await axios.patch(`${WIKI_ADMIN_URL}/category`, {
      categorySeq,
      useYn,
    });
    const newWikiList = [...categoryList];
    newWikiList[index].useYn = useYn;
    setCategoryList(newWikiList);
    return res;
  };

  return (
    <>
      <PageHeader
        className="wiki-category-header"
        title="위키 카테고리 목록"
        extra={[
          <Button
            key="add-category"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            카테고리 추가
          </Button>,
        ]}
      />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: '90%',
        }}
      >
        <CategoryModal
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
        />
        <Table
          style={{ width: '100%' }}
          loading={isLoading}
          columns={columns}
          dataSource={categoryList}
          pagination={false}
          // scroll={{ x: 1750, y: 1000 }}
        />
        <CategoryPagination total={categoryCount} onChange={onChangePage} />
      </div>
    </>
  );
};
export default Category;

const CategoryPagination = ({
  total = 10,
  onChange = () => {},
}: {
  total?: number;
  onChange?: (page: number, size: number) => void;
}): JSX.Element => {
  const [curruntPage, setCurruntPage] = useState<number>(1);

  const onClickPage = (page: number, size: number) => {
    setCurruntPage(page);
    onChange(page, size);
  };

  return (
    <>
      <Pagination
        style={{ marginTop: '20px', width: '100%', textAlign: 'end' }}
        current={curruntPage}
        total={total}
        onChange={onClickPage}
        pageSizeOptions={['10', '20', '50']}
      />
    </>
  );
};
