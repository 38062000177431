import { Form, Input, Modal, Button, message } from 'antd';
import React, { ReactElement, useState } from 'react';
import axios from 'axios';

import WikiRelateSelect from '../WikiAdd/WikiRelateSelect';
import WikiTagInput from '../WikiAdd/WikiTagInput';

import { WIKI_ADMIN_URL } from '../common/const';

interface TagModalProps {
  visible: boolean;
  onClose?: (data?: any) => void;
  onSubmit?: (data?: any) => void;
}

const WikiTagModal: React.FC<TagModalProps> = ({
  visible,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const [formValues, setFormValues] = useState<any>({});
  const [form] = Form.useForm();

  const onOk = async () => {
    const { relatedDocuments, tag } = formValues;
    const documentSeqList = relatedDocuments.data.map(
      (item: any) => item.documentSeq,
    );
    const tagData = {
      documentSeqList,
      tagList: tag,
    };
    const res = await axios.post(`${WIKI_ADMIN_URL}/tag`, tagData);
    form.resetFields();
    setFormValues({});
    onSubmit();
    // return res;
  };

  const onCancel = async () => {
    form.resetFields();
    setFormValues({});
    onClose();
  };

  return (
    <Modal
      title="태그 추가"
      visible={visible}
      closable={false}
      width={750}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          취소
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          완료
        </Button>,
      ]}
    >
      <Form
        style={{
          display: visible ? 'block' : 'none',
          width: '80%',
        }}
        form={form}
        name="태그 추가"
        onValuesChange={(changedValues, allValues) => {
          setFormValues(allValues);
        }}
        scrollToFirstError
      >
        <Form.Item name="relatedDocuments" label="위키 문서">
          <WikiRelateSelect />
        </Form.Item>
        <Form.Item
          label="태그"
          name="tag"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <WikiTagInput type="tag" onChange={() => {}} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WikiTagModal;
