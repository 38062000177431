import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { DescText } from '../../../../../@type/webView/wiki';

import { palette } from '../../../../../lib/styles/palette';

import SubTitle from '../../../../common/SubTitle';

interface DescriptionTextProps {
  data: DescText;
}

function DescriptionText({
  data: { title, contents },
}: DescriptionTextProps): ReactElement {
  return (
    <div css={wrap}>
      {title && <SubTitle>{title}</SubTitle>}
      <div className="content">{contents}</div>
    </div>
  );
}

export default DescriptionText;

const wrap = css`
  padding: 0 20px;
  font-size: 15px;
  line-height: 23px;
  white-space: pre-wrap;

  .content {
    color: ${palette.gray6};
  }
`;
