import { Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import WikiRelateSelect from './WikiRelateSelect';
import WikiTagInput from './WikiTagInput';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface WikiRelateFormProps {
  visible: boolean;
  nextStep: (data: any) => void;
  prevStep: () => void;
}

const relateExample = [
  {
    type: 'relatedDocuments',
    data: [{ documentSeq: 27 }, { documentSeq: 135 }],
  },
  { type: 'relatedProducts', data: { searchKeyword: '콘스탄틴 어베인' } },
];

const WikiRelateForm: React.FC<WikiRelateFormProps> = ({
  visible,
  nextStep,
  prevStep,
}) => {
  const [form] = Form.useForm();
  const [relatedDocuments, setRelatedDocuments] = useState<any>([]);
  const [relatedProduct, setRelatedProduct] = useState<any>([]);
  const [labelList, setLabelList] = useState<string[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [excludeTagList, setExcludeTagList] = useState<string[]>([]);

  const onFinish = (values: any) => {
    const data = { ...values };
    if (values.relatedProducts)
      data.relatedProducts = {
        type: 'relatedProducts',
        data: {
          searchKeyword: values.relatedProducts,
        },
      };
    nextStep(data);
  };

  return (
    <Form
      style={{
        display: visible ? 'block' : 'none',
        width: '80%',
      }}
      {...formItemLayout}
      form={form}
      name="위키 관련 정보"
      initialValues={{}}
      onFinish={onFinish}
      onValuesChange={(changedValues, allValues) => {
        // console.log(allValues);
      }}
    >
      <Form.Item name="relatedDocuments" label="관련 위키 문서">
        <WikiRelateSelect />
      </Form.Item>
      <Form.Item name="relatedProducts" label="관련 상품 키워드">
        <Input
          className="relatedProducts"
          id="relatedProducts"
          placeholder="상품 키워드"
        />
      </Form.Item>
      <Form.Item name="label" label="문서 라벨">
        <WikiTagInput type="label" onChange={setLabelList} />
      </Form.Item>
      <Form.Item name="tag" label="문서 태그">
        <WikiTagInput type="tag" onChange={setTagList} />
      </Form.Item>
      <Form.Item name="excludeTag" label="문서 제외 태그">
        <WikiTagInput type="excludeTag" onChange={setExcludeTagList} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              prevStep();
            }}
            style={{ marginRight: '10px' }}
          >
            이전
          </Button>

          <Button type="primary" htmlType="submit">
            완료
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default WikiRelateForm;
