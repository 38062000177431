/* eslint-disable no-nested-ternary */
import { ForwardedRef, forwardRef, ReactElement, useState } from 'react';

import { css } from '@emotion/react';

import { htmlStringToReactElement } from '../../../common/parser';

import TextEllipsisSpan from '../../../lib/styles/TextEllipsisSpan';
import { palette } from '../../../lib/styles/palette';

interface MoreStringProps {
  string: string;
  stringLimit: number;
  lineCount?: number;
  lineHeight?: number;
  foldedState: boolean;
  parentNickname?: string;
  className?: string;
}

function PureMoreString(
  {
    string,
    stringLimit,
    lineCount = 2,
    lineHeight = 24,
    foldedState = true,
    parentNickname,
    className,
  }: MoreStringProps,
  ref: ForwardedRef<HTMLSpanElement>,
): ReactElement {
  const [isOverflow] = useState<boolean>(string.length > stringLimit);
  // eslint-disable-next-line react/no-unstable-nested-components
  const SpanElement = () => {
    return foldedState ? (
      <TextEllipsisSpan
        ref={ref}
        lineCount={lineCount}
        lineHeight={lineHeight}
        id="textSpan"
        css={textArea}
      >
        {htmlStringToReactElement(string.substring(0, stringLimit))}
        {isOverflow && <span>...</span>}
      </TextEllipsisSpan>
    ) : (
      <span ref={ref} css={textArea}>
        {htmlStringToReactElement(string)}
      </span>
    );
  };

  return (
    <div className={className}>
      {parentNickname && <span css={nickname}>{`@${parentNickname} `}</span>}
      <SpanElement />
    </div>
  );
}

const nickname = css`
  color: ${palette.blue5};
`;

const textArea = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const MoreString = forwardRef<HTMLSpanElement, MoreStringProps>(PureMoreString);
export default MoreString;
