import React, { ReactElement, useState } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import {
  getValidResourceUrl,
  checkIsValidResourceUrl,
} from '../../../../common/lib';

interface ImageProps {
  src: string;
  alt: string;
  style?: SerializedStyles;
  isSquare?: boolean;
  className?: string;
  imgType?: 'image' | 'wikiImage';
  sizeType?: 'normal' | 'thumbnail';
  dimOpacity?: number;
}

function Image({
  src,
  alt,
  style,
  isSquare = true,
  className,
  imgType = 'image',
  sizeType = 'normal',
  dimOpacity = 0,
}: ImageProps): ReactElement {
  const [isHeightLonger, setIsHeightLonger] = useState(false);

  // 원본 이미지 width,hegiht 비교
  const onLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.persist();
    const img = e.target;
    if (img.clientWidth < img.clientHeight) setIsHeightLonger(true);
  };

  return (
    <div css={imgCard(isSquare, dimOpacity)} className={className}>
      {isSquare ? (
        <img
          css={[imgSquare(isHeightLonger), style]}
          src={
            src.startsWith('data:image') || src.startsWith('blob:')
              ? src
              : getValidResourceUrl(src, imgType, sizeType)
          }
          alt={alt}
          width={1000}
          height={1000}
          onLoad={onLoad}
          referrerPolicy={checkIsValidResourceUrl(src) ? 'no-referrer' : ''}
        />
      ) : (
        <img
          css={[imgRegtangle, style]}
          src={
            src.startsWith('data:image') || src.startsWith('blob:')
              ? src
              : getValidResourceUrl(src, imgType, sizeType)
          }
          referrerPolicy={checkIsValidResourceUrl(src) ? 'no-referrer' : ''}
          alt={alt}
        />
      )}
    </div>
  );
}

const imgCard = (isSquare: boolean, dimOpacity: number) => css`
  position: relative;
  width: 100%;
  padding-top: ${isSquare ? '100% ' : ''};
  overflow: hidden;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, ${dimOpacity});
    top: 0;
    left: 0;
  }
`;

const imgSquare = (isHeightLonger: boolean) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${isHeightLonger ? '100% ' : 'auto'};
  height: ${isHeightLonger ? 'auto' : '100%'};
  border-radius: 4px;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;
const imgRegtangle = css`
  width: 100%;
  height: 100%;
`;

export default Image;
