import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WikiAdd from './WikiAdd/WikiAdd';
import WikiUpdate from './WikiUpdate/WikiUpdate';
import WikiList from './WikiList/WikiList';
import WikiTag from './WikiTag/WikiTag';
import WikiExcludeTag from './WikiExcludeTag/WikiExcludeTag';
import WikiRequest from './WikiRequest/WikiRequest';
import Category from './Category/Category';
import WikiComment from './WikiComment/WikiComment';
import WikiInfo from './WikiInfo/WikiInfo';

const Router: React.FC = () => (
  <Routes>
    <Route path="/" element={<WikiList />} />
    <Route path="wiki" element={<WikiList />} />
    <Route path="wiki/list" element={<WikiList />} />
    <Route path="wiki/add" element={<WikiAdd />} />
    <Route path="wiki/info/:documentSeq" element={<WikiInfo />} />
    <Route path="wiki/update/:documentSeq" element={<WikiUpdate />} />
    <Route path="tag" element={<WikiTag />} />
    <Route path="excludeTag" element={<WikiExcludeTag />} />
    <Route path="request" element={<WikiRequest />} />
    <Route path="category" element={<Category />} />
    <Route path="comment" element={<WikiComment />} />
  </Routes>
);

export default Router;
