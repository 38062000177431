/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import { ReactElement } from 'react';
import Slider from 'react-slick';

import { css } from '@emotion/react';

import { getValidResourceUrl } from '../../common/lib';

import {
  WikiDocument,
  WikiCategory,
  WikiItems,
} from '../../@type/webView/wiki';

import Image from '../../components/web/common/image/Image';

import { palette } from '../../lib/styles/palette';

import WikiItemPerType from './WikiItemPerType';
import WikiPrice from './WikiPrice/WikiPrice';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const MWEB_URL = 'https://m.joongna.com';

interface WikiContentProps {
  document: WikiDocument;
  documentCategories: WikiCategory[];
  documentImgs: string[];
  documentItems: any[];
}

function WikiContent({
  document,
  documentCategories,
  documentImgs,
  documentItems,
}: WikiContentProps): ReactElement {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const { originalPrice, unopenedPrice, usedPrice, standardPriceKeyword } =
    document;

  return (
    <div css={wrap}>
      {documentImgs.length > 0 && (
        <Slider className="slide-wrap" {...settings}>
          {documentImgs.length > 0 &&
            documentImgs.map((img: any, index: number) => {
              let imgUrl = '';
              if (typeof img === 'string') {
                imgUrl = img;
              } else if (img.originFileObj) {
                imgUrl = URL.createObjectURL(img.originFileObj);
              } else {
                imgUrl = img.url;
              }
              return (
                <div key={`상품이미지-${index}`}>
                  <Image
                    src={getValidResourceUrl(imgUrl, 'wikiImage')}
                    alt={`상품이미지-${index}`}
                    className="wikiImg"
                  />
                </div>
              );
            })}
        </Slider>
      )}
      {(originalPrice || usedPrice || unopenedPrice) && (
        <div className="price-wrap">
          <WikiPrice title="신품가격" price={originalPrice} />
          <WikiPrice title="중고가격" price={usedPrice} />
          <WikiPrice title="신품가격" price={unopenedPrice} />
          {standardPriceKeyword && (
            <div className="searchPrice-wrap">시세 조회하기</div>
          )}
        </div>
      )}
      {documentItems.map((documentItem: WikiItems) => {
        return (
          <div key={documentItem.type}>
            <WikiItemPerType
              documentItem={documentItem}
              document={document}
              documentCategories={documentCategories}
            />
          </div>
        );
      })}
    </div>
  );
}

const wrap = css`
  margin-top: 32px;
  white-space: pre-wrap;
  position: relative;

  .slide-wrap {
    padding: 0 20px;
    overflow: hidden;
    cursor: pointer;

    .wikiImg {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        top: 0;
        left: 0;
      }
    }

    .slick-dots {
      bottom: 18px;
      cursor: initial;
      position: absolute;
      left: 0;
      li {
        margin: 0 4px;
        button:before {
          width: 6px;
          height: 6px;
          background-color: ${palette.black10};
          border-radius: 50%;
          font-size: 0px;
          border: 0;
          opacity: 0.25;
        }
      }
      .slick-active {
        button:before {
          opacity: 0.75;
        }
      }
    }
  }

  .price-wrap {
    padding: 32px 20px 36px 20px;
  }

  .searchPrice-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 13px;
    font-size: 14px;
    text-decoration: underline;
    color: ${palette.orange9};
  }
  .desc-wrap {
    margin: 0 20px;
    margin-bottom: 32px;
    border-top: 1px solid ${palette.gray0};

    .content {
      color: ${palette.gray6};
      font-size: 15px;
      line-height: 23px;
    }
  }
`;

export default WikiContent;
