/* eslint-disable no-restricted-syntax */
import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { WikiCategory } from '../../../@type/webView/wiki';

import Icon from '../../common/Icon';

interface CategoryBreadcrumbProps {
  documentCategory: WikiCategory[];
  className: string;
}

const CategoryBreadcrumb = ({
  documentCategory,
  className,
}: CategoryBreadcrumbProps): ReactElement => {
  return (
    <div css={wrap} className={className}>
      {documentCategory?.map(({ categorySeq, categoryName }) => {
        return (
          <div key={categorySeq} css={menu}>
            <button>
              <span>{categoryName}</span>
            </button>

            <div className="arrow-icon">
              <Icon icon="arrowRight" size={12} stroke="rgb(163, 163, 163)" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const wrap = css`
  display: flex;
  align-items: center;
  color: rgb(163, 163, 163);
  font-size: 13px;
`;

const menu = css`
  display: flex;
  align-items: center;

  button {
    background-color: #ffffff;
    color: rgb(163, 163, 163);

    & ::selection {
      background-color: none;
      color: rgb(163, 163, 163);
    }
  }

  :last-of-type {
    .arrow-icon {
      display: none;
    }
  }
`;

export default CategoryBreadcrumb;
