import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { palette } from '../../lib/styles/palette';

interface DivideLineProps {
  height?: number;
  className?: string;
}

export default function DivideLine({
  height = 8,
  className,
}: DivideLineProps): ReactElement {
  return <div css={wrap(height)} className={className} />;
}

const wrap = (height: number) => css`
  background-color: ${palette.gray0};
  height: ${height}px;
`;
