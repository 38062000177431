import { Button, Upload } from 'antd';
import React, { ReactElement, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import './WikiUploadImg.css';

interface WikiUploadImgProps {
  maxCount?: number;
  onChange?: (data: any) => void;
}

const WikiUploadImg: React.FC<WikiUploadImgProps> = ({
  onChange: propsOnChange,
  maxCount = 10,
}) => {
  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onChange = (info: any): void => {
    if (propsOnChange) propsOnChange(info?.fileList);
  };

  return (
    <Upload
      accept="image/*"
      className="upload-list-inline"
      listType="picture"
      customRequest={dummyRequest}
      onChange={onChange}
      maxCount={maxCount}
    >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};

export default WikiUploadImg;
