import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { Layout, Menu, Avatar, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Router from './Router';
import Login from './Login/Login';
import GlobalStyles from './lib/styles/GlobalStyles';
import './App.css';
import wikiLogo from './asset/img/wiki/wikiLogo.svg';
import clearWikiIdToken from './util/clearWikiIdToken';
import writeWikiIdToken from './util/writeWikiIdToken';
import 'antd/dist/antd.less';

const { Header, Sider, Content } = Layout;

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [adminInfo, setAdminInfo] = useState<any>(
    sessionStorage.getItem('WikiAdminInfo')
      ? JSON.parse(sessionStorage.getItem('WikiAdminInfo') || '{}')
      : null,
  );
  const [wikiIdToken, setWikiIdToken] = useState(
    sessionStorage.getItem('wikiIdToken'),
  );

  useEffect(() => {
    async function fetchAndSet() {
      if (wikiIdToken) {
        await writeWikiIdToken(wikiIdToken);
        setIsLoading(false);
      }
    }
    fetchAndSet();
  }, [wikiIdToken]);

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        {wikiIdToken ? (
          <Layout style={{ height: '100vh', overflow: 'auto' }}>
            <Header
              style={{
                padding: '0px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <img alt={wikiLogo} src={wikiLogo} style={{ height: '100%' }} />
              <div style={{ height: '100%', color: 'white', fontSize: 15 }}>
                <Popover
                  placement="bottomRight"
                  content={<div onClick={clearWikiIdToken}>Logout</div>}
                  trigger="click"
                >
                  <Avatar style={{ marginRight: 10 }} icon={<UserOutlined />} />
                  {adminInfo
                    ? `${adminInfo.name}(${adminInfo.department})`
                    : ''}
                </Popover>
              </div>
            </Header>
            <Layout>
              <Sider width={200} className="site-layout-background">
                <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
                  <Menu.Item key="category">
                    <Link to="/category">카테고리</Link>
                  </Menu.Item>
                  <Menu.Item key="wikiList">
                    <Link to="/wiki/list">문서 목록</Link>
                  </Menu.Item>
                  <Menu.Item key="wiki">
                    <Link to="/wiki/add">문서 추가</Link>
                  </Menu.Item>
                  <Menu.Item key="comment">
                    <Link to="/comment">댓글 목록</Link>
                  </Menu.Item>
                  <Menu.Item key="tag">
                    <Link to="/tag">태그 설정</Link>
                  </Menu.Item>
                  <Menu.Item key="excludeTag">
                    <Link to="/excludeTag">제외 태그 설정</Link>
                  </Menu.Item>
                  <Menu.Item key="request">
                    <Link to="/request">요청/정정 문서</Link>
                  </Menu.Item>
                </Menu>
              </Sider>
              {isLoading || (
                <Content style={{ height: '100%' }}>
                  <Router />
                </Content>
              )}
            </Layout>
          </Layout>
        ) : (
          <Login setWikiIdToken={setWikiIdToken} setAdminInfo={setAdminInfo} />
        )}
      </BrowserRouter>
    </>
  );
};

export default App;
