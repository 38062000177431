import { Form, Input, Modal, Button, message } from 'antd';
import React, { ReactElement, useState } from 'react';
import axios from 'axios';

import WikiCategorySelect from '../WikiAdd/WikiCategorySelect';
import { WIKI_ADMIN_URL } from '../common/const';

interface CategoryModalProps {
  visible: boolean;
  onClose?: (data?: any) => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  visible,
  onClose = () => {},
}) => {
  const [formValues, setFormValues] = useState<any>({});
  const [form] = Form.useForm();

  const onOk = async () => {
    const { parentsCategory, categoryName } = formValues;
    if (parentsCategory && categoryName) {
      const categoryData = {
        categoryTypeCode: 'WIKI',
        parentsCategorySeq:
          parentsCategory[parentsCategory.length - 1].categorySeq,
        categoryDepth:
          parentsCategory[parentsCategory.length - 1].categoryDepth + 1,
        categoryName,
        useYn: 0,
        delYn: 0,
      };
      const res = await axios.post(`${WIKI_ADMIN_URL}/category`, categoryData);
      form.resetFields();
      setFormValues({});
      onClose();
      return res;
    }
    message.error('카테고리명을 입력해주세요.');
    return false;
  };

  return (
    <Modal
      title="카테고리 추가"
      // centered
      visible={visible}
      closable={false}
      width={750}
      footer={[
        <Button
          key="back"
          onClick={() => {
            form.resetFields();
            setFormValues({});
            onClose();
          }}
        >
          취소
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          완료
        </Button>,
      ]}
    >
      <Form
        style={{
          display: visible ? 'block' : 'none',
          width: '80%',
        }}
        form={form}
        name="카테고리 추가"
        onValuesChange={(changedValues, allValues) => {
          setFormValues(allValues);
        }}
        initialValues={{}}
        scrollToFirstError
      >
        <Form.Item
          name="parentsCategory"
          label="상위 카테고리"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <WikiCategorySelect maxDepth={2} />
        </Form.Item>

        <Form.Item
          label="카테고리명"
          name="categoryName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryModal;
