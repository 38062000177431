import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import _ from 'lodash';
import WikiUploadImg from './WikiUploadImg';
import { WIKI_IMG_URL } from '../common/const';

interface WikiImageFormProps {
  formId: string;
  initialValues: any;
  onChange: (id: any, data: any) => void;
}

const WikiImageForm: React.FC<WikiImageFormProps> = ({
  formId,
  initialValues,
  onChange,
}) => {
  const [img, setImg] = useState<any>();
  const onValuesChange = (changedValues: any, allValues: any) => {
    const data = {
      type: 'image',
      data: {
        title: allValues.title,
        imageUrl: allValues.descriptionImage[0],
      },
    };
    onChange(formId, data);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      setImg(e[0]);
      return e;
    }
    setImg(e?.fileList[0]);
    return e?.fileList;
  };

  return (
    <Form
      name="이미지"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onValuesChange={onValuesChange}
      autoComplete="off"
      initialValues={
        initialValues
          ? {
              title: initialValues.data.title,
              descriptionImage: _.isString(initialValues.data.imageUrl)
                ? [
                    {
                      uid: `0`,
                      name: initialValues.data.imageUrl.split('/')?.pop(),
                      url: `${WIKI_IMG_URL}${initialValues.data.imageUrl}`,
                      status: 'done',
                    },
                  ]
                : initialValues.data.imageUrl,
            }
          : {}
      }
    >
      <Form.Item
        label="제목"
        name="title"
        rules={[{ required: true, message: '항목의 제목을 입력해주세요' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="descriptionImage"
        label="이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <WikiUploadImg maxCount={1} />
      </Form.Item>
    </Form>
  );
};

export default WikiImageForm;
