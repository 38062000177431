import { ReactElement } from 'react';

import { css } from '@emotion/react';

import Icon from '../../components/common/Icon';

import { palette } from '../../lib/styles/palette';

interface TagProps {
  title: string;
}

function Tag({ title }: TagProps): ReactElement {
  return (
    <div css={wrap}>
      <span>{title}</span>
      <Icon
        className="icon"
        icon="arrowRight"
        size={13}
        stroke={palette.gray6}
      />
    </div>
  );
}

const wrap = css`
  height: 32px;
  padding: 6px 12px;
  margin-right: 6px;
  border: 1px solid ${palette.gray2};
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  color: ${palette.gray6};
  font-size: 14px;

  .icon {
    margin-left: 6px;
  }
`;

export default Tag;
