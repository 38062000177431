import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import { ItemRelatedDocuments } from '../../../../@type/webView/wiki';

import Image from '../../../../components/web/common/image/Image';

import TextEllipsisSpan from '../../../../lib/styles/TextEllipsisSpan';
import { palette } from '../../../../lib/styles/palette';

import WikiDefaultImg from '../../../../asset/img/wiki/wiki-default.png';

import DivideLine from '../../../common/DivideLine';
import SubTitle from '../../../common/SubTitle';

interface WikiItemRelatedDocumentsProps {
  data: ItemRelatedDocuments[];
}
function WikiItemRelatedDocuments({
  data: documents,
}: WikiItemRelatedDocumentsProps): ReactElement {
  return (
    <>
      <div css={wrap}>
        <SubTitle>관련 위키 문서</SubTitle>
        {documents.map(
          ({ documentSeq, documentTitle, documentDesc, documentImgUrl }) => (
            <Link
              key={documentSeq}
              to={`/wiki/detail/${documentSeq}`}
              css={card}
            >
              <div className="document-img">
                <Image
                  src={documentImgUrl ?? WikiDefaultImg}
                  dimOpacity={documentImgUrl ? 0.05 : 0}
                  imgType="wikiImage"
                  sizeType="thumbnail"
                  alt={documentTitle}
                />
              </div>
              <div className="document-content">
                <div className="title">
                  <TextEllipsisSpan lineCount={1}>
                    {documentTitle}
                  </TextEllipsisSpan>
                </div>
                <div className="desc">
                  <TextEllipsisSpan lineCount={2} lineHeight={22}>
                    {documentDesc}
                  </TextEllipsisSpan>
                </div>
              </div>
            </Link>
          ),
        )}
      </div>
      <DivideLine />
    </>
  );
}

const wrap = css`
  margin-top: 32px;
  padding: 0 20px;
`;

const card = css`
  display: flex;
  color: ${palette.black10};
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${palette.gray0};

  :hover {
    color: ${palette.black10};
  }
  :last-of-type {
    border-bottom: none;
  }

  .document-img {
    width: 80px;
    flex: 0 0 auto;
    img {
      width: 80px;
      border-radius: 4px;
    }
  }

  .document-content {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    margin-top: 4px;

    .title {
      font-weight: 500;
      margin-bottom: 7px;
      line-height: 20px;
      white-space: pre-wrap;
    }

    .desc {
      font-size: 14px;
      color: ${palette.gray6};
      line-height: 22px;
    }
  }
`;

export default WikiItemRelatedDocuments;
