import axios from 'axios';

import { WIKI_ADMIN_URL } from '../common/const';

const uploadImageToS3 = async (presignedUrl: string, file: any) => {
  const config = {
    headers: {
      'Content-Type': file.type,
    },
    transformRequest: (data: any, headers: any) => {
      // eslint-disable-next-line no-param-reassign
      delete headers.common.Authorization;
      return data;
    },
  };
  try {
    await axios.put(presignedUrl, file, config);
  } catch (e) {
    console.dir(`Error in CommentInputImage: ${e}`);
  }
};

const postPresignedUrl = async (fileName: string) => {
  const path = '/image/presignedUrl';
  const body = {
    fileNames: [`${fileName}`],
  };
  const res = await axios.post(`${WIKI_ADMIN_URL}${path}`, body);
  return res.data.data[0];
};

const patchWiki = async (wiki: any) => {
  const path = '/wiki';
  const res = await axios.patch(`${WIKI_ADMIN_URL}${path}`, wiki);
  return res.data.data[0];
};

const putWikiImages = async (wiki: any) => {
  const path = '/image';
  const res = await axios.put(`${WIKI_ADMIN_URL}${path}`, wiki);
  return res.data.data[0];
};

const modifyDescription = async (description: any, documentSeq: number) => {
  const res = await Promise.all(
    description.data.map(async (item: any) => {
      if (item.type === 'image' && typeof item.data.imageUrl !== 'string') {
        const fileName = `/document/${documentSeq}/desc_${encodeURI(
          item.data.imageUrl.name,
        )}`;
        const presignedUrl = await postPresignedUrl(fileName);
        await uploadImageToS3(
          presignedUrl.s3UploadUrl,
          item.data.imageUrl.originFileObj,
        );
        return {
          type: 'image',
          data: {
            title: item.data.title,
            imageUrl: fileName,
          },
        };
      }
      return item;
    }),
  );
  return { type: 'description', data: res };
};

const modifyRelatedDocuments = async (relatedDocuments: any) => {
  const res = await Promise.all(
    relatedDocuments.data.map(async (item: any) => {
      return {
        documentSeq: item.documentSeq,
      };
    }),
  );
  return { type: 'relatedDocuments', data: res };
};

const modifyItems = async (documentItems: any, documentSeq: number) => {
  return Promise.all(
    documentItems.map(async (item: any) => {
      if (item.type === 'description') {
        return modifyDescription(item, documentSeq);
      }
      if (item.type === 'relatedDocuments') {
        return modifyRelatedDocuments(item);
      }
      return item;
    }),
  );
};

const modifyDocumentImgs = async (documentImgs: any, documentSeq: number) => {
  return Promise.all(
    documentImgs.map(async (item: any) => {
      if (typeof item !== 'string') {
        const fileName = `/document/${documentSeq}/${encodeURI(item.name)}`;
        const presignedUrl = await postPresignedUrl(fileName);
        await uploadImageToS3(presignedUrl.s3UploadUrl, item.originFileObj);
        return fileName;
      }
      return item;
    }),
  );
};

const postWiki = async (data: any) => {
  const {
    document,
    documentCategories,
    documentItems,
    documentImgs,
    documentLabels,
    tag,
    excludeTag,
  } = data;
  const wiki = {
    categorySeq: documentCategories[documentCategories.length - 1].categorySeq,
    documentTitle: document.documentTitle,
    documentDesc: document.documentDesc,
    exposeYn: 0, // 생성 후 바로 공개 처리를 막기 위해 0으로 고정
    // nullable values
    originalPrice: document.originalPrice,
    unopenedPrice: document.unopenedPrice,
    usedPrice: document.usedPrice,
    standardPriceKeyword: document.standardPriceKeyword,
    documentItems: [],
  };

  const res = await axios.post(`${WIKI_ADMIN_URL}/wiki`, {
    wiki,
    tag,
    excludeTag,
    label: documentLabels,
  });

  const newDocumentSeq = res.data.data.documentSeq;

  const modifiedItems = await modifyItems(documentItems, newDocumentSeq);
  // upload image

  await patchWiki({
    documentSeq: newDocumentSeq,
    documentItems: modifiedItems,
  });

  const modifiedDocumentImgs = await modifyDocumentImgs(
    documentImgs,
    newDocumentSeq,
  );

  await putWikiImages({
    documentSeq: newDocumentSeq,
    documentImgs: modifiedDocumentImgs,
  });

  return res;
};

const createWiki = async (wiki: any) => {
  // 1. 위키 생성
  // 1-2. 위키 생성 응답으로 받은 이미지 업로드 url로 이미지 업로드
  // 2. 위키 이미지 생성
  // 3. 위키 라벨 생성
  // 4. 위키 통계 생성
  // 5. 위키 태그 생성
  // 6. 위키 제외 태그 생성
  const res = await postWiki(wiki);
  return res;
};
export default createWiki;
