import axios from 'axios';
import { message } from 'antd';

const clearWikiIdToken = () => {
  message.warning('로그아웃 되었습니다.');
  sessionStorage.removeItem('wikiIdToken');
  axios.defaults.headers.common.Authorization = '';
  document.location.href = '/';
};
export default clearWikiIdToken;
