import { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';

import { css } from '@emotion/react';

import { getValidResourceUrl } from '../../../../common/lib';
import { getWonUnit } from '../../../../common/parser';

import Icon from '../../../../components/common/Icon';
import Image from '../../../../components/web/common/image/Image';
import Carousel from '../../../../components/web/common/swipe/Carousel';

import { palette } from '../../../../lib/styles/palette';

import DivideLine from '../../../common/DivideLine';
import SubTitle from '../../../common/SubTitle';

const PLATFORM_TYPE = { APP: 1, AUTO: 2, PEACE_MARKET: 3, CAFE: 4 };
interface WikiItemRelatedProductsProps {
  data: any;
}

function WikiItemRelatedProducts({
  data: { searchKeyword },
}: WikiItemRelatedProductsProps): ReactElement {
  const [relatedProducts, setRelatedProducts] = useState<any>([]);

  const getRelataedProducts = async (): Promise<any> => {
    const res = await axios.post(
      `https://search-api.joongna.com/v26/search/total`,
      {
        searchWord: searchKeyword,
        quantity: 20,
      },
    );
    setRelatedProducts(res.data.data.items);
    return res.data.data.items;
  };

  useEffect(() => {
    getRelataedProducts();
  }, [searchKeyword]);

  if (!relatedProducts.length && relatedProducts.length < 1) return <></>;

  return (
    <>
      <div css={wrap}>
        <div className="title-wrap">
          <SubTitle>관련 상품</SubTitle>
        </div>
        <Carousel>
          {relatedProducts
            .slice(0, 20)
            .map(
              ({
                seq,
                title,
                url,
                price,
                platformType,
                articleSeq,
                jnPayBadgeFlag,
              }: any) => {
                const isCafeProduct = platformType === PLATFORM_TYPE.CAFE;
                return (
                  <button key={isCafeProduct ? articleSeq : seq} css={product}>
                    <div css={imgWrap}>
                      <Image
                        src={getValidResourceUrl(url)}
                        alt={title}
                        dimOpacity={0.05}
                      />
                      {jnPayBadgeFlag && (
                        <Icon
                          css={jnPayBadge}
                          icon="jnPayBadge"
                          aria-label="중나페이 badge"
                        />
                      )}
                    </div>
                    <span className="product-price">{getWonUnit(price)}</span>
                    <span className="product-title">{title}</span>
                  </button>
                );
              },
            )}
        </Carousel>
      </div>
      <DivideLine />
    </>
  );
}
const wrap = css`
  margin-top: 32px;
  padding: 0 0 32px 20px;
  white-space: pre-wrap;

  .title-wrap {
    padding-right: 20px;
  }

  .carousel-viewport {
  }
  width: 100%;
  overflow: visible;
`;

const product = css`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-right: 12px;

  .product-price {
    display: block;
    margin-bottom: 4px;
    float: left;
    font-weight: 500;
    color: ${palette.black10};
  }

  .product-title {
    display: block;
    text-overflow: ellipsis;
    width: 152px;
    text-align: start;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    line-height: 20px;
    color: ${palette.black10};
  }
`;

const imgWrap = css`
  position: relative;
  width: 152px;
  height: 152px;
  overflow: hidden;
  margin-bottom: 12px;
  border-radius: 4px;
  display: block;
  img {
    width: 100%;
    height: 100%;
  }
`;

const jnPayBadge = css`
  position: absolute;
  width: 30px;
  height: 16.29px;
  top: 12px;
  right: 12px;
  border-radius: 2.25px;
  color: ${palette.white};
  background: ${palette.orange9};
`;

export default WikiItemRelatedProducts;
