import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Pagination, Select, PageHeader, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import './WikiRequest.css';

import { WIKI_ADMIN_URL } from '../common/const';

const { Option } = Select;

interface RequestType {
  key: string;
  name: string;
  documentSeq: number;
  categoryName: string;
  documentTitle: string;
  documentDesc: string;
  regName: string;
  regDate: string;
  exposeYn: number;
  processStatus: string;
}

const WikiRequest = (): JSX.Element => {
  const [requestList, setRequestList] = useState<RequestType[]>([]);
  const [requestCount, setRequestCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const columns: ColumnsType<RequestType> = [
    {
      title: '번호',
      dataIndex: 'requestSeq',
      key: 'requestSeq',
      width: 90,
    },
    {
      title: '요청 구분',
      dataIndex: 'requestTypeCode',
      key: 'requestTypeCode',
      width: 100,
      render: (requestTypeCode: string, record: any) => {
        switch (requestTypeCode) {
          case 'PROD':
            return <Tag color="green">생성요청</Tag>;
          case 'CORRECTION':
            return <Tag color="blue">정정요청</Tag>;
          default:
            return <Tag color="default">{`${requestTypeCode}}`}</Tag>;
        }
      },
    },
    {
      title: '관련 카테고리',
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: 140,
    },
    {
      title: '관련 문서',
      dataIndex: 'documentSeq',
      key: 'documentSeq',
      width: 180,
      render: (documentSeq: number, record: any) => {
        return documentSeq ? (
          <Link
            to={`/wiki/info/${documentSeq}`}
          >{`${documentSeq}. ${record.documentTitle}`}</Link>
        ) : (
          '-'
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'requestTitle',
      key: 'requestTitle',
      width: 250,
    },
    {
      title: '내용',
      dataIndex: 'requestContent',
      key: 'requestContent',
    },
    {
      title: '가게 번호',
      dataIndex: 'storeSeq',
      key: 'storeSeq',
      width: 120,
    },
    {
      title: '상태',
      dataIndex: 'processStatus',
      key: 'processStatus',
      width: 120,
      render: (processStatus: string, record: any, index: number) => {
        return (
          <Select
            value={processStatus}
            onChange={(value) => {
              onChangeProcessStatus({
                requestSeq: record.requestSeq,
                processStatus: value,
                index,
              });
            }}
          >
            <Option value="WAITING">대기</Option>
            <Option value="HOLD">보류</Option>
            <Option value="PROCESSING">처리중</Option>
            <Option value="COMPLETED">완료</Option>
          </Select>
        );
      },
    },
    {
      title: '등록일',
      dataIndex: 'regDate',
      key: 'regDate',
      width: 180,
    },
  ];

  useEffect(() => {
    getRequestList({});
  }, []);

  const onChangePage = (page: number, size: number) => {
    getRequestList({ page, size });
  };

  const onChangeProcessStatus = async ({
    requestSeq,
    processStatus,
    index,
  }: {
    requestSeq: number;
    processStatus: string;
    index: number;
  }) => {
    const res = await axios.patch(`${WIKI_ADMIN_URL}/request`, {
      requestSeq,
      processStatus,
    });
    const newWikiList = [...requestList];
    newWikiList[index].processStatus = processStatus;
    setRequestList(newWikiList);
  };

  const getRequestList = async ({
    page = 1,
    size = 10,
  }: {
    page?: number;
    size?: number;
  }) => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/request?page=${page}&size=${size}`,
    );
    const { rows, count } = res.data.data;
    setRequestList(rows);
    setRequestCount(count);
    setIsLoading(false);
    return rows;
  };

  return (
    <>
      <PageHeader className="wiki-request-header" title="위키 요청/정정 목록" />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: '90%',
        }}
      >
        <Table
          rowClassName={(record: any) => {
            if (record.processStatus === 'HOLD') return 'hold-row';
            return record.requestSeq;
          }}
          style={{ width: '100%' }}
          loading={isLoading}
          columns={columns}
          dataSource={requestList}
          pagination={false}
          // scroll={{ x: 1750, y: 1000 }}
        />
        <RequestPagination total={requestCount} onChange={onChangePage} />
      </div>
    </>
  );
};
export default WikiRequest;

const RequestPagination = ({
  total = 10,
  onChange = () => {},
}: {
  total?: number;
  onChange?: (page: number, size: number) => void;
}): JSX.Element => {
  const [curruntPage, setCurruntPage] = useState<number>(1);

  const onClickPage = (page: number, size: number) => {
    setCurruntPage(page);
    onChange(page, size);
  };

  return (
    <>
      <Pagination
        style={{ marginTop: '20px', width: '100%', textAlign: 'end' }}
        current={curruntPage}
        total={total}
        onChange={onClickPage}
        pageSizeOptions={['10', '20', '50']}
      />
    </>
  );
};
