import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface WikiDescriptionSelectorProps {
  onSelect: (value: string) => void;
}

const WikiDescriptionSelector: React.FC<WikiDescriptionSelectorProps> = ({
  onSelect: propsOnSelect,
}) => {
  const onSelect = (value: string) => {
    propsOnSelect(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="상세 유형 선택"
      onSelect={onSelect}
      value="상세 유형 선택"
    >
      <Option value="table">표</Option>
      <Option value="text">텍스트</Option>
      <Option value="image">이미지</Option>
      {/* <Option value="imageText">이미지/텍스트</Option> */}
    </Select>
  );
};

export default WikiDescriptionSelector;
