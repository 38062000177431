import { ReactElement } from 'react';

import parse from 'html-react-parser';

/** number 입력 시 원화로 변경 */
export const getWonUnit = (price: number, isWonMark = true): string => {
  if (price === 0) return '무료나눔';
  const commaPrice = price.toLocaleString('en-US');
  return isWonMark ? `${commaPrice}원` : commaPrice;
};

/** string response 개행 처리 */
export const replaceCRLF = (string: string): string => {
  return string
    .replace(/\n\n/gi, '<br><br>')
    .replace(/\r\n/gi, '<br>')
    .replace(/\r/gi, '<br>')
    .replace(/\n/gi, '<br>');
};

/** string을 받아 개행처리 후 ReactElement로 return  */
export const htmlStringToReactElement = (
  string: string,
): string | ReactElement | ReactElement[] => {
  return parse(replaceCRLF(string));
};
