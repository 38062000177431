/* eslint-disable react/no-array-index-key */
import { ReactElement, useState, useRef, useEffect } from 'react';

import { css } from '@emotion/react';

import { htmlStringToReactElement } from '../../../../common/parser';

import {
  WikiDocument,
  WikiDocumentDesc,
  WikiCategory,
} from '../../../../@type/webView/wiki';

import MoreString from '../../../../components/common/MoreString';

import { palette } from '../../../../lib/styles/palette';

import DivideLine from '../../../common/DivideLine';
import SubTitle from '../../../common/SubTitle';
import DescriptionImg from './DescriptionImg/DescriptionImg';
import DescriptionTable from './DescriptionTable';
import DescriptionText from './DescriptionText';
import DescriptionTextImg from './DescriptionTextImg';

interface WIkiItemDescriptionProps {
  data: WikiDocumentDesc[];
  document: WikiDocument;
  documentCategories: WikiCategory[];
}

function WikiItemDescription({
  data: descriptionList,
  document: { documentSeq, documentDesc },
  documentCategories,
}: WIkiItemDescriptionProps): ReactElement {
  const STRING_DESC_CNT = 150;
  const descRef = useRef<HTMLDivElement>(null);
  const [isDescOpen, setIsDescOpen] = useState(true);

  const handleDescMoreBtn = () => {
    setIsDescOpen(!isDescOpen);

    // 개요 '접기' click 시 개요로 이동
    if (isDescOpen && descRef.current)
      window.scrollTo(0, descRef.current.offsetTop);
  };

  return (
    <>
      <div css={wrap}>
        <div className="description-wrap">
          <SubTitle>개요</SubTitle>
          <div className="content" ref={descRef}>
            {descriptionList.length > 0 ? (
              <MoreString
                string={documentDesc}
                stringLimit={STRING_DESC_CNT}
                lineCount={5}
                foldedState={!isDescOpen}
              />
            ) : (
              <span>{htmlStringToReactElement(documentDesc)}</span>
            )}
          </div>
        </div>
        {isDescOpen && (
          <div className="description-item-wrap">
            {descriptionList.length > 0 &&
              descriptionList.map((props: WikiDocumentDesc) => {
                switch (props.type) {
                  case 'text':
                    return (
                      <DescriptionText key={props.type} data={props.data} />
                    );
                  case 'image':
                    return (
                      <DescriptionImg key={props.type} data={props.data} />
                    );
                  case 'text/image':
                    return (
                      <DescriptionTextImg key={props.type} data={props.data} />
                    );
                  case 'table':
                    return (
                      <DescriptionTable key={props.type} data={props.data} />
                    );
                  default:
                    return <></>;
                }
              })}
          </div>
        )}
        <DivideLine height={1} className="moreBtn-wrap" />
        {descriptionList.length > 0 && (
          <button css={moreBtn} type="button" onClick={handleDescMoreBtn}>
            {isDescOpen ? '접기' : '더 보기'}
          </button>
        )}
        <DivideLine />
      </div>
      <DivideLine />
    </>
  );
}
export default WikiItemDescription;

const wrap = css`
  .description-wrap {
    margin: 0 20px;
    padding-top: 40px;
    border-top: 1px solid ${palette.gray0};

    .content {
      color: ${palette.gray6};
      font-size: 15px;
      white-space: pre-wrap;
      word-break: break-all;

      span {
        line-height: 24px;
      }
    }

    .moreBtn-wrap {
      margin-top: 32px;
    }
  }

  .description-item-wrap {
    > div {
      margin-top: 40px;
    }
  }

  .moreBtn-wrap {
    margin-top: 32px;
  }

  .request-wrap {
    text-decoration: none;
    color: ${palette.black10};
  }
`;

const moreBtn = css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 18px 0;
  background-color: transparent;
  font-size: 14px;
  color: ${palette.gray6};
  cursor: pointer;
`;
