import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Table, Pagination, Switch, PageHeader, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import _ from 'lodash';

import WikiExcludeTagModal from './WikiExcludeTagModal';
import { WIKI_ADMIN_URL } from '../common/const';

interface TagType {
  key: string;
  excludeTagSeq: number;
  excludeTagName: string;
  documentSeq: number;
  documentTitle: string;
  delYn: number;
  regDate: string;
  updateDate: string;
}

const WikiExcludeTag = (): JSX.Element => {
  const [excludeTagList, setExcludeTagList] = useState<TagType[]>([]);
  const [excludeTagListCount, setExcludeTagListCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const paginationRef = useRef<any>();

  const columns: ColumnsType<TagType> = [
    {
      title: '번호',
      dataIndex: 'excludeTagSeq',
      key: 'excludeTagSeq',
      width: 90,
    },
    {
      title: '문서',
      dataIndex: 'documentTitle',
      key: 'documentTitle',
      render: (documentTitle: string, record: TagType) => {
        return `${record.documentSeq}. ${documentTitle}`;
      },
    },
    {
      title: '제외 태그',
      dataIndex: 'excludeTagName',
      key: 'excludeTagName',
    },
    {
      title: '사용 여부',
      dataIndex: 'delYn',
      key: 'delYn',
      width: 100,
      render: (delYn: number, record: TagType, index: number) => {
        return (
          <Switch
            checked={delYn === 0}
            onChange={() => {
              changeDelete({
                excludeTagSeq: record.excludeTagSeq,
                delYn: delYn ? 0 : 1,
                index,
              });
            }}
          />
        );
      },
    },
    {
      title: '등록일',
      dataIndex: 'regDate',
      key: 'regDate',
      width: 180,
    },
  ];

  useEffect(() => {
    getExcludeTagList({});
  }, []);

  const getExcludeTagList = async ({
    page = 1,
    size = 10,
  }: {
    page?: number;
    size?: number;
  }) => {
    setIsLoading(true);
    const res = await axios.get(
      `${WIKI_ADMIN_URL}/excludeTag?page=${page}&size=${size}`,
    );
    const { rows, count } = res.data.data;
    setExcludeTagList(rows);
    setExcludeTagListCount(count);
    setIsLoading(false);
    paginationRef?.current?.chagePage(page);
    return rows;
  };

  const changeDelete = async ({ excludeTagSeq, delYn, index }: any) => {
    const res = await axios.patch(`${WIKI_ADMIN_URL}/excludeTag`, {
      excludeTagSeq,
      delYn,
    });
    setExcludeTagList((oldTagList) => {
      const newTagList = [...oldTagList];
      newTagList[index].delYn = delYn;
      return newTagList;
    });
    return res;
  };

  const onChange = (page: number, size: number) => {
    getExcludeTagList({ page, size });
  };

  return (
    <>
      <PageHeader
        className="wiki-excludeTag-header"
        title="위키 제외 태그 목록"
        extra={[
          <Button
            key="wiki-add-excludeTag"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            제외 태그 추가
          </Button>,
        ]}
      />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: '1200px',
        }}
      >
        <WikiExcludeTagModal
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
          onSubmit={() => {
            setModalVisible(false);
            getExcludeTagList({});
          }}
        />
        <Table
          rowKey="excludeTagSeq"
          style={{ width: '100%' }}
          loading={isLoading}
          columns={columns}
          dataSource={[...excludeTagList]}
          pagination={false}
        />
        <ExcludeTagListPagination
          total={excludeTagListCount}
          onChange={onChange}
          ref={paginationRef}
        />
      </div>
    </>
  );
};
export default WikiExcludeTag;

const ExcludeTagListPagination = forwardRef(
  (
    {
      total = 10,
      onChange = () => {},
    }: {
      total?: number;
      onChange?: (page: number, size: number) => void;
    },
    ref: any,
  ): JSX.Element => {
    const [curruntPage, setCurruntPage] = useState<number>(1);

    useImperativeHandle(ref, () => ({
      chagePage(page: number) {
        setCurruntPage(page);
      },
    }));

    const onClickPage = (page: number, size: number) => {
      setCurruntPage(page);
      onChange(page, size);
    };

    return (
      <>
        <Pagination
          style={{ marginTop: '20px', width: '100%', textAlign: 'end' }}
          current={curruntPage}
          total={total}
          onChange={onClickPage}
          pageSizeOptions={['10', '20', '50']}
        />
      </>
    );
  },
);
ExcludeTagListPagination.displayName = 'ExcludeTagListPagination';
