/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable no-param-reassign */
import { useCallback } from 'react';

const useTableHook = () => {
  /* antd table setting - column */
  const getBaseColumnObjArr = useCallback((columnList: any, columnCnt: any) => {
    const checkedColmnList =
      columnList && columnList.length > 0
        ? columnList
        : new Array(columnCnt).fill('');

    const baseColObjArr = checkedColmnList.map(
      (column: string, idx: number) => {
        if (idx === 0)
          return {
            title: column,
            fixed: 'left',
            dataIndex: 'baseRow',
            key: 'baseRow',
          };
        return {
          title: column || '',
          dataIndex: `row${idx}`,
          key: idx,
        };
      },
    );
    return baseColObjArr;
  }, []);

  /* antd table setting - content */
  const getContentsObjArr = useCallback((contentsList: string[][]) => {
    const contentsObjArr = contentsList.map(
      (contents: string[], idx: number) => {
        const contentObjKey = { key: idx };
        const contentObjRow = contents.reduce(
          (obj: any, content: string, index: number) => {
            obj[index ? `row${index}` : 'baseRow'] = content;
            return obj;
          },
          {},
        );
        const contentObj = { ...contentObjKey, ...contentObjRow };
        return contentObj;
      },
    );
    return contentsObjArr;
  }, []);

  return { getBaseColumnObjArr, getContentsObjArr };
};

export default useTableHook;
