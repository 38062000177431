import React, { useState } from 'react';
import axios from 'axios';
import { WIKI_ADMIN_URL } from '../common/const';
import WikiDebounceSelect from './WikiDebounceSelect';

// Usage of DebounceSelect
interface WikiValue {
  label: string;
  value: string;
}

interface WikiRelateSelectProps {
  initialValues: any;
  onChange?: (value: any) => void;
}

const WikiRelateSelect: React.FC<WikiRelateSelectProps> = ({
  initialValues,
  onChange,
}) => {
  const [relatedWiki, setRelatedWiki] = useState<WikiValue[]>(
    initialValues || [],
  );

  const getRelatedWikiList = async (input: string | number): Promise<any> => {
    try {
      const res = await axios.get(
        `${WIKI_ADMIN_URL}/wiki/related?searchKeyword=${input}`,
      );
      const data = res.data.data.map((item: any) => {
        return {
          label: `${item.documentSeq}. ${item.documentTitle}`,
          key: item.documentSeq,
          value: JSON.stringify(item),
        };
      });
      return data;
    } catch (error) {
      return error;
    }
  };

  const triggerChange = (values: any) => {
    const parsedValues = values?.map((item: any) => {
      return JSON.parse(item.value);
    });
    onChange?.({
      type: 'relatedDocuments',
      data: parsedValues,
    });
  };

  return (
    <WikiDebounceSelect
      mode="multiple"
      value={relatedWiki}
      placeholder="문서 제목 or 번호"
      fetchOptions={getRelatedWikiList}
      onChange={(newValue) => {
        triggerChange(newValue as WikiValue[]);
        setRelatedWiki(newValue as WikiValue[]);
      }}
      style={{ width: '100%' }}
    />
  );
};

export default WikiRelateSelect;
