import React, { useEffect, useState } from 'react';
import { Steps, Card, Result, PageHeader } from 'antd';

import WikiDescription from './WikiDescription';
import WikiDataForm from './WikiDataForm';
import WikiRelateForm from './WikiRelateForm';
import WikiThumbnail from './WikiThumbnail';

const { Step } = Steps;

const WikiAdd = (): JSX.Element => {
  const [wiki, setWiki] = useState<any>();
  const [currentStep, setCurrentStep] = useState(0);
  const [wikiData, setWikiData] = useState<any>({});
  const [wikiDescription, setWikiDescription] = useState<any>({});
  const [wikiRelated, setWikiRelated] = useState<any>({});
  const [createdWiki, setCreatedWiki] = useState<any>({});

  const nextStep = (): void => {
    setCurrentStep(currentStep + 1);
  };
  const prevStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  const generateWikiData = (data: any) => {
    setWikiData(data);
    setCurrentStep(currentStep + 1);
  };

  const generateWikiDescription = (data: any) => {
    setWikiDescription(data);
    setCurrentStep(currentStep + 1);
  };

  const generateWikiRelated = (data: any) => {
    setWikiRelated(data);
    generateWiki({
      data: wikiData,
      description: wikiDescription,
      related: data,
    });
  };

  const generateWiki = ({ data, description, related }: any) => {
    const tempWiki: any = {
      data: {
        documentImgs: data.documentImgs ? data.documentImgs : [],
        documentLabels: related.label,
        documentCategories: data.documentCategories,
        document: {
          documentTitle: data.documentTitle,
          documentDesc: data.documentDesc,
          originalPrice: data.originalPrice,
          unopenedPrice: data.unopenedPrice,
          usedPrice: data.usedPrice,
          standardPriceKeyword: data.standardPriceKeyword,
        },
        documentItems: [description],
      },
    };
    if (related.relatedDocuments) {
      tempWiki.data.documentItems.push(related.relatedDocuments);
    }
    if (related.relatedProducts) {
      tempWiki.data.documentItems.push(related.relatedProducts);
    }
    if (related.label) {
      tempWiki.data.label = related.label;
    }
    if (related.tag) {
      tempWiki.data.tag = related.tag;
    }
    if (related.excludeTag) {
      tempWiki.data.excludeTag = related.excludeTag;
    }

    setWiki(tempWiki);
    setCurrentStep(currentStep + 1);
    return '';
  };

  return (
    <>
      <PageHeader className="wiki-comment-header" title="문서 추가" />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Steps
          current={currentStep}
          style={{ width: '40vw', marginBottom: '20px' }}
        >
          <Step
            title="문서 정보"
            description="문서의 기본 정보를 입력해주세요."
          />
          <Step
            title="문서 세부"
            description="문서 세부 정보를 입력해주세요."
          />
          <Step title="관련 문서" description="관련된 문서를 지정해주세요." />
        </Steps>
        <Card
          style={{
            width: '80%',
          }}
        >
          <WikiDataForm
            visible={currentStep === 0}
            nextStep={generateWikiData}
          />
          <WikiDescription
            visible={currentStep === 1}
            nextStep={generateWikiDescription}
            prevStep={prevStep}
          />
          <WikiRelateForm
            visible={currentStep === 2}
            nextStep={generateWikiRelated}
            prevStep={prevStep}
          />
          {currentStep === 3 && wiki ? (
            <WikiThumbnail
              wiki={wiki}
              nextStep={(data) => {
                setCreatedWiki(data);
                nextStep();
              }}
              prevStep={prevStep}
            />
          ) : null}
          {currentStep === 4 && createdWiki ? (
            <Result
              status="success"
              title={`${createdWiki.data.data.documentTitle} 위키가 생성되었습니다.`}
            />
          ) : null}
        </Card>
      </div>
    </>
  );
};
export default WikiAdd;
