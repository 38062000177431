import { Form, Input, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import WikiRelateSelect from './WikiRelateSelect';
import WikiTagInput from './WikiTagInput';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface WikiRelateFormProps {
  initialValues: any;
  visible: boolean;
  nextStep: (data: any) => void;
  prevStep: () => void;
}

const WikiRelateForm: React.FC<WikiRelateFormProps> = ({
  initialValues,
  visible,
  nextStep,
  prevStep,
}) => {
  const [labelList, setLabelList] = useState<string[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [excludeTagList, setExcludeTagList] = useState<string[]>([]);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const data = { ...values };
    if (values.relatedProducts)
      data.relatedProducts = {
        type: 'relatedProducts',
        data: {
          searchKeyword: values.relatedProducts,
        },
      };
    nextStep(data);
  };

  return (
    <Form
      style={{
        display: visible ? 'block' : 'none',
        width: '80%',
      }}
      {...formItemLayout}
      form={form}
      name="위키 관련 정보"
      initialValues={{
        relatedDocuments: {
          type: 'relatedDocuments',
          data: initialValues.relatedDocuments,
        },
        relatedProducts: _.find(initialValues.documentItems, {
          type: 'relatedProducts',
        })?.data?.searchKeyword,
        label: initialValues.label.map((item: any) => item.labelName),
        tag: initialValues.tag.map((item: any) => item.tagName),
        excludeTag: initialValues.excludeTag.map(
          (item: any) => item.excludeTagName,
        ),
      }}
      onFinish={onFinish}
      onValuesChange={(changedValues, allValues) => {
        // console.log('[allValues]', allValues);
      }}
    >
      <Form.Item name="relatedDocuments" label="관련 위키 문서">
        <WikiRelateSelect
          initialValues={initialValues.relatedDocuments.map((item: any) => {
            return { label: item.documentTitle, value: JSON.stringify(item) };
          })}
        />
      </Form.Item>
      <Form.Item name="relatedProducts" label="관련 상품 키워드">
        <Input
          className="relatedProducts"
          id="relatedProducts"
          placeholder="상품 키워드"
        />
      </Form.Item>
      <Form.Item name="label" label="문서 라벨">
        <WikiTagInput
          type="label"
          initialValues={initialValues.label.map((item: any) => item.labelName)}
          onChange={setLabelList}
        />
      </Form.Item>
      <Form.Item name="tag" label="문서 태그">
        <WikiTagInput
          type="tag"
          initialValues={initialValues.tag.map((item: any) => item.tagName)}
          onChange={setTagList}
        />
      </Form.Item>
      <Form.Item name="excludeTag" label="문서 제외 태그">
        <WikiTagInput
          type="excludeTag"
          initialValues={initialValues.excludeTag.map(
            (item: any) => item.excludeTagName,
          )}
          onChange={setExcludeTagList}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              prevStep();
            }}
            style={{ marginRight: '10px' }}
          >
            이전
          </Button>

          <Button type="primary" htmlType="submit">
            완료
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default WikiRelateForm;
