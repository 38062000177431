import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { WikiDocument, WikiCategory } from '../../@type/webView/wiki';

import CategoryBreadcrumb from '../../components/webView/CategoryBreadcrumb/CategoryBreadcrumb';

import Tag from '../common/Tag';

interface WikiTitleProps {
  document: WikiDocument;
  documentCategories: WikiCategory[];
  documentLabels: string[];
}

function WikiTitle({
  document,
  documentCategories,
  documentLabels,
}: WikiTitleProps): ReactElement {
  const { documentTitle } = document;

  return (
    <div css={wrap}>
      <CategoryBreadcrumb
        documentCategory={documentCategories}
        className="category"
      />
      <h1 className="title">{documentTitle}</h1>
      {documentLabels.length > 0 && (
        <div className="lables">
          {documentLabels.map((label) => (
            <Tag key={label} title={label} />
          ))}
        </div>
      )}
    </div>
  );
}

const wrap = css`
  padding: 0 20px;
  .category {
    margin: 40px 0 12px 0;
  }

  .title {
    font-size: 29px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 12px;
    white-space: pre-wrap;
  }

  .lables {
    button {
      margin-right: 8px;
      font-size: 14px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }
`;

export default WikiTitle;
